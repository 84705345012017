import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Paper, IconButton } from "@mui/material";

interface LocationLineProps {
  products: Product[];
  onLocationSelect: (event: { target: { value: any; fullLocationData?: any } }) => void;
  selectedLocationId: number | null;
  setDocumentType?: (type: string) => void;
  selectedCustomer?: any;
}

interface Product {
  id: number;
  name: string;
  ref: string;
  AdvertisingLocation: Array<{
    id: number;
    name?: string;
    city?: string;
    isTemporary?: boolean;
  }>;
  productLocalisation: {
    city: string;
  };
}

export default function LocationLine({ products, onLocationSelect, selectedLocationId, setDocumentType, selectedCustomer }: LocationLineProps) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [openProductId, setOpenProductId] = React.useState<number | null>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  // Gérer la fermeture du dropdown lors d'un clic à l'extérieur
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
        setOpenProductId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setOpenProductId(null); // Réinitialiser le produit ouvert lors de l'ouverture du dropdown
    }
  };

  const handleProductClick = (productId: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Empêcher la propagation pour éviter la fermeture du dropdown
    setOpenProductId(openProductId === productId ? null : productId);
  };

  const handleLocationSelect = (locationId: number, product: Product, index: number) => {
    const locationData = {
      id: product.id,
      ref: product.ref,
      name: product.name,
      city: product.productLocalisation.city,
      emplacementNumber: index + 1,
      advertisingLocationId: locationId
    };
    
    const formattedDescription = `Location ${product.name} - Emplacement ${index + 1} - ${product.productLocalisation.city}`;
    
    onLocationSelect({
      target: {
        value: {
          ...locationData,
          description: formattedDescription
        },
        fullLocationData: {
          commercial: selectedCustomer,
          emplacement: {
            id: product.id,
            ref: product.ref,
            name: product.name,
            city: product.productLocalisation.city,
            advertisingLocationId: locationId,
            AdvertisingLocation: [{
              id: locationId,
              isTemporary: false
            }]
          }
        }
      }
    });
    
    setIsDropdownOpen(false);
    setOpenProductId(null);
  };

  const getSelectedLocationDisplay = () => {
    for (const product of products) {
      const location = product.AdvertisingLocation.find(loc => loc.id === selectedLocationId);
      if (location) {
        const index = product.AdvertisingLocation.indexOf(location);
        const isTemporary = location.isTemporary ? " (Temporaire)" : "";
        return `${product.name} - Emplacement ${index + 1} - ${product.productLocalisation.city}${isTemporary}`;
      }
    }
    return "Sélectionner un emplacement";
  };

  const handleAddLocation = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (setDocumentType) {
      setDocumentType('location');
    }
  };

  useEffect(() => {
    if (selectedLocationId && products.length > 0) {
      const product = products.find(p => 
        p.AdvertisingLocation.some(loc => loc.id === selectedLocationId)
      );
      
      if (product) {
        const location = product.AdvertisingLocation.find(loc => loc.id === selectedLocationId);
        if (location) {
          const index = product.AdvertisingLocation.indexOf(location);
          const formattedDescription = `Location ${product.name} - Emplacement ${index + 1} - ${product.productLocalisation.city}`;
          
          const locationData = {
            id: product.id,
            ref: product.ref,
            name: product.name,
            city: product.productLocalisation?.city || '',
            advertisingLocationId: location.id,
            isTemporary: false,
            description: formattedDescription
          };

          onLocationSelect({
            target: {
              value: locationData,
              fullLocationData: {
                commercial: selectedCustomer,
                emplacement: {
                  id: product.id,
                  ref: product.ref,
                  name: product.name,
                  city: product.productLocalisation?.city || '',
                  advertisingLocationId: location.id,
                  AdvertisingLocation: [{
                    id: location.id,
                    isTemporary: false
                  }]
                }
              }
            }
          });
        }
      }
    }
  }, [selectedLocationId, products, selectedCustomer]);

  return (
    <Box sx={{ position: 'relative', width: '100%' }} ref={dropdownRef}>
      <ListItemButton
        onClick={handleDropdownClick}
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          minHeight: '35px',
          padding: '4px 8px',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <ListItemText
          primary={getSelectedLocationDisplay()}
          primaryTypographyProps={{
            fontSize: '0.85rem',
            noWrap: true,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
        <ExpandMore
          sx={{
            transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s',
          }}
        />
      </ListItemButton>

      <Collapse in={isDropdownOpen} timeout="auto">
        <Paper
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1000,
            maxHeight: '200px',
            overflowY: 'auto',
            mt: 0.5,
            boxShadow: 3,
          }}
        >
          <List
            sx={{ 
              width: '100%', 
              bgcolor: '#FFFFFF',
              '& .MuiListItemButton-root': {
                py: 0.5,
              },
            }}
            component="nav"
            dense
          >
            {products.map((product) => (
              <React.Fragment key={product.id}>
                <ListItemButton 
                  onClick={(e) => handleProductClick(product.id, e)}
                  sx={{
                    backgroundColor: openProductId === product.id ? '#f5f5f5' : 'inherit',
                    color: "#000000",
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <ListItemText 
                    primary={product.name}
                    primaryTypographyProps={{ 
                      fontSize: '0.9rem',
                      fontWeight: openProductId === product.id ? 'bold' : 'normal'
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      size="small"
                      onClick={handleAddLocation}
                      sx={{ 
                        mr: 1,
                        color: '#000000',
                        '&:hover': {
                          color: '#1976d2',
                        }
                      }}
                    >
                      <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                    {openProductId === product.id ? <ExpandLess /> : <ExpandMore />}
                  </Box>
                </ListItemButton>
                <Collapse in={openProductId === product.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding dense>
                    {product.AdvertisingLocation.map((location, index) => (
                      <ListItemButton
                        key={location.id}
                        sx={{ 
                          pl: 4,
                          backgroundColor: selectedLocationId === location.id ? '#e3f2fd' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#e8f4f8',
                          },
                          color: "#000000",
                        }}
                        onClick={() => handleLocationSelect(location.id, product, index)}
                      >
                        <ListItemText
                          primary={`Emplacement ${index + 1} - ${product.productLocalisation.city}`}
                          primaryTypographyProps={{ 
                            fontSize: '0.85rem',
                            color: selectedLocationId === location.id ? '#1976d2' : 'inherit'
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Collapse>
    </Box>
  );
}