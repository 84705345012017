import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Product {
  "@id": string;
  "@type": string;
  id: number;
  ref: string;
  name: string;
  AdvertisingLocation: Array<{
    "@id": string;
    "@type": string;
    id: number;
    historyAdvertisingLocations: Array<{
      "@id": string;
      "@type": string;
      customerId: {
        "@id": string;
        "@type": string;
        companyName: string;
      };
      AdvertisingLocation: string;
      startDate: string;
      endDate: string;
      currentlyActive: boolean;
      customerCompanyName: string;
    }>;
  }>;
  productLocalisation: {
    city: string;
  };
}

interface StepperEmplacementHistoryProps {
  colors: any;
  products: Product[];
}

function Row(props: { product: Product; location: Product['AdvertisingLocation'][0]; colors: any }) {
  const { product, location, colors } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: colors.primary[400] }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ backgroundColor: colors.primary[400] }}>
          {product.name}
        </TableCell>
        <TableCell align="right" sx={{ backgroundColor: colors.primary[400] }}>
          {product.productLocalisation.city}
        </TableCell>
        <TableCell align="right" sx={{ backgroundColor: colors.primary[400] }}>
          {product.AdvertisingLocation.length > 1 ? ` ${product.AdvertisingLocation.indexOf(location) + 1}/${product.AdvertisingLocation.length}` : ''}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historique
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>Date de début</TableCell>
                    <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>Date de fin</TableCell>
                    <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>Client</TableCell>
                    <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>Statut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {location.historyAdvertisingLocations?.map((historyRow) => (
                    <TableRow key={historyRow["@id"]}>
                      <TableCell sx={{ backgroundColor: colors.primary[400] }}>
                        {historyRow.startDate ? new Date(historyRow.startDate).toLocaleDateString() : '-'}
                      </TableCell>
                      <TableCell sx={{ backgroundColor: colors.primary[400] }}>
                        {historyRow.endDate ? new Date(historyRow.endDate).toLocaleDateString() : '-'}
                      </TableCell>
                      <TableCell sx={{ backgroundColor: colors.primary[400] }}>
                        {historyRow.customerCompanyName || '-'}
                      </TableCell>
                      <TableCell sx={{ backgroundColor: colors.primary[400] }}>
                        {historyRow.currentlyActive ? 'Actif' : 'Inactif'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function StepperEmplacementHistory({ colors, products }: StepperEmplacementHistoryProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }} />
            <TableCell sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>
              Nom de l'emplacement
            </TableCell>
            <TableCell align="right" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>
              Ville
            </TableCell>
            <TableCell align="right" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}>
              Numéro d'emplacement
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.flatMap(product => 
            product.AdvertisingLocation.map((location, index) => (
              <Row 
                key={`${product.id}-${location.id}`}
                product={{
                  ...product,
                  name: `${product.name} ${index + 1}/${product.AdvertisingLocation.length}`
                }} 
                location={location} 
                colors={colors} 
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}