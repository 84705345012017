import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { baseURL } from "../../../constants/URL";
import VisibilityIcon from "@mui/icons-material/Visibility";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditUpload({ onImageChange, imageName }) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageToValidate, setImageToValidate] = useState(!!imageName);

  const imageUrl = imageName
    ? `${baseURL}/assets/uploads/products/${imageName}`
    : null;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Vérification de la taille du fichier
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      setErrorMessage(`Le fichier est trop volumineux. Taille maximale : 10MB`);
      setError(true);
      event.target.value = "";
      return;
    }

    console.log("Informations de l'image :", {
      nom: file.name,
      taille: file.size + " bytes",
      type: file.type,
      dernièreModification: new Date(file.lastModified).toLocaleString(),
    });

    const validExtensions = ["jpg", "jpeg", "png", "webp"];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      setErrorMessage(
        "Format de fichier non autorisé. Veuillez choisir une image JPG, JPEG, PNG ou WEBP."
      );
      setError(true);
      event.target.value = "";
      return;
    }

    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target.result);
      if (!openModal) {
        setOpenModal(true);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleCloseError = () => {
    setError(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const cancelImage = () => {
    setImageSrc(null);
    setSelectedFile(null);
    setOpenModal(false);
  };

  const validateImage = () => {
    setImageToValidate(true);
    setOpenModal(false);
    if (selectedFile && onImageChange) {
      console.log("Image validée :", {
        nom: selectedFile.name,
        taille: selectedFile.size + " bytes",
        type: selectedFile.type,
        dernièreModification: new Date(
          selectedFile.lastModified
        ).toLocaleString(),
      });
      onImageChange(selectedFile);
    }
  };

  const removeImage = () => {
    setImageToValidate(false);
    setImageSrc(null);
    setSelectedFile(null);
    if (onImageChange) {
      onImageChange(null);
    }
  };

  const handleViewImage = () => {
    setOpenModal(true);
  };

  return (
    <>
      {imageToValidate || imageName ? (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleViewImage}
            startIcon={<VisibilityIcon />}
          >
            Voir la photo
          </Button>
        </Box>
      ) : (
        <Button
          component="label"
          role={undefined}
          variant="contained"
          color="secondary"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Ajouter une photo
          <VisuallyHiddenInput
            type="file"
            accept=".jpg,.jpeg,.png,.webp"
            onChange={handleFileChange}
          />
        </Button>
      )}

      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Image Récupérée</DialogTitle>
        <DialogContent>
          {(imageSrc || imageUrl) && (
            <>
              <img
                src={imageSrc || imageUrl}
                alt="Uploaded"
                style={{ maxWidth: "100%", maxHeight: "350px" }}
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                {!selectedFile ? (
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    color="secondary"
                  >
                    Modifier la photo
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={handleFileChange}
                    />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={validateImage}
                  >
                    Valider
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelImage}
                >
                  Annuler
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
