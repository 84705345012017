import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { toast } from "react-toastify";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import UploadImage from "./UploadImage";

const AddAffiche = () => {
  const navigate = useNavigate();
  const store = useGlobalStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState([]);

  const [formValues, setFormValues] = useState({
    product: {
      ref: "",
      user: "",
      imageName: ""
    },
    localisation: {
      latitude: 0,
      longitude: 0,
    }
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResult = await store.product.getUsers();
        if (usersResult.success) {
          setUsers(usersResult.data);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs:",
          error
        );
        toast.error("Erreur lors de la récupération des utilisateurs");
      }
    };

    fetchUsers();
  }, [store.product]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (["ref", "user", "name"].includes(name)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        localisation: {
          ...prevValues.localisation,
          [name]: value,
        },
      }));
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      console.log("Image reçue:", {
        name: file.name,
        type: file.type,
        size: file.size
      });
      
      setFormValues((prev) => ({
        ...prev,
        product: {
          ...prev.product,
          imageFile: file,
        },
      }));
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      
      if (formValues.product.imageFile instanceof File) {
        console.log("Ajout de l'image au FormData:", {
          name: formValues.product.imageFile.name,
          type: formValues.product.imageFile.type,
          size: formValues.product.imageFile.size
        });
        formData.append('image', formValues.product.imageFile, formValues.product.imageFile.name);
      }

      formData.append('ref', formValues.product.ref || '');
      formData.append('name', formValues.product.name || '');
      formData.append('user', formValues.product.user || '');
      formData.append('latitude', formValues.localisation.latitude ? Number(formValues.localisation.latitude) : 0);
      formData.append('longitude', formValues.localisation.longitude ? Number(formValues.localisation.longitude) : 0);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value instanceof File ? `File: ${value.name}` : value}`);
      }

      const result = await store.product.postProduct(formData);

      if (result.success && result.data && result.data.data) {
        const productId = result.data.data.productId;
        toast.success("Produit créé avec succès !");
        navigate(`/product/edit/${productId}`);
      } else {
        console.error(
          "Erreur dans le formulaire",
          result.error || "Aucune erreur spécifiée"
        );
        toast.error(
          "Erreur dans le formulaire: " + 
          (result.error || "Aucune erreur spécifiée")
        );
      }
    } catch (error) {
      console.error("Erreur lors de la création du produit :", error);
      toast.error("Erreur lors de la création du produit");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Dashboard
        title={"Produit"}
        subtitle={"Créer un produit sans localisation"}
        element={
          <Box sx={{ display: "flex", gap: 2 }}>
            <UploadImage onImageChange={handleImageChange} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/product")}
            >
              <AddIcon />
              Retour
            </Button>
          </Box>
        }
      />

      <Box sx={{ maxWidth: "1380px", margin: "auto", padding: "20px" }}>
        <Box m="20px">
          <Grid
            container
            spacing={2}
            p={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <form onSubmit={handleCreate}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Référence du produit"
                    name="ref"
                    value={formValues.ref}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Nom du produit"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                      sx: { transform: "translate(12px, 7px) scale(0.75)" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Select
                    fullWidth
                    variant="filled"
                    displayEmpty
                    name="user"
                    value={formValues.user}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Choisir un commercial</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Box>
                <Button
                  sx={{ mt: "20px" }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Création..." : "Créer"}
                </Button>
              </Box>
            </form>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AddAffiche;
