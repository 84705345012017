import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { tokens } from "../../theme";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { Table } from "../../components/table/Table";
import { ToastContainer } from "react-toastify";
import Dashboard from "../dashboard/DashboardIndex.tsx";
import { InvoiceStatusModal } from "./invoiceStatusModal/InvoiceStatusModal.tsx";
import { useTheme } from "@emotion/react";
import { PreviewInvoiceModal } from "./previewInvoice/previewInvoiceModal";
import { toJS } from "mobx";

interface Invoice {
  id: number;
  number: string;
  customer: string;
  dueDate: string;
  totalWithTax: string;
  paymentStatus: string;
}

type Filter = "all" | "unpaid" | "draft" | "overdue" | "paid" | "canceled";

const Invoices: React.FC = () => {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoicesData, setInvoicesData] = useState<Invoice[]>([]);
  const [filter, setFilter] = useState<Filter>("all");
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<Invoice | null>(null);

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const result = await store.invoice.getInvoices();
      if (result.success) {
        const data = Array.isArray(result.data) ? result.data : [result.data];
        const rawData = data.map((invoice: any) => ({
          id: invoice.id,
          number: invoice.number,
          customer: invoice.customer.companyName,
          issuedAt: invoice.formatedIssuedAt,
          dueDate: invoice.formatedDueDate 
            ? invoice.formatedDueDate
            : invoice.invoiceGenerationSchedule?.nextGenerationDate 
              ? new Date(invoice.invoiceGenerationSchedule.nextGenerationDate).toLocaleDateString()
              : invoice.formatedIssuedAt,
          totalWithTax: (invoice.totalWithTax / 100).toFixed(2) + " €",
          statut: invoice.status,
          paymentStatus: invoice.status === 'LATE' ? 'LATE' : invoice.paymentStatus,
        }));
        setInvoicesData(rawData);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(invoicesData, 'invoicesData');

  const handleFilterChange = (
    event: React.SyntheticEvent,
    newFilter: Filter
  ) => {
    setFilter(newFilter);
  };

  const handleRowStatusClick = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setIsStatusOpen(true);
    const selectedData = invoicesData.find((item) => item.id === invoice.id);
    setSelectedInvoiceData(selectedData || null);
  };

  const handleRowPreviewInvoiceClick = (selectedInvoice: Invoice) => {
    setSelectedInvoice(selectedInvoice);
    setIsPreviewOpen(true);
    const selectedData = invoicesData.find(
      (item) => item.id === selectedInvoice.id
    );
    if (selectedData) {
      setSelectedInvoiceData(selectedData);
    } else {
      console.error(
        "La facture sélectionnée n'a pas été trouvée dans la liste complète des factures."
      );
    }
  };

  const handlePaidStatus = async () => {
    setIsStatusOpen(false);
    fetchInvoices();
  };

  const renderTabLabel = (label: string, count: number) => (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography>{label}</Typography>
      <Typography color="textSecondary">{`(${count})`}</Typography>
    </Box>
  );

  const filterData = (data: Invoice[], filter: Filter) => {
    switch (filter) {
      case "unpaid":
        return data.filter(
          (invoice) =>
            invoice.paymentStatus === "UNPAID" || // Statut de facture non payée
            invoice.paymentStatus === "LATE" // Facture avec paiement en retard
        );
      case "draft":
        return data.filter((invoice) => invoice.paymentStatus === "DRAFT");
      case "overdue":
        return data.filter((invoice) => invoice.paymentStatus === "LATE");
      case "paid":
        return data.filter((invoice) => invoice.paymentStatus === "PAID");
      case "canceled":
        return data.filter((invoice) => invoice.paymentStatus === "CANCELLED");
      default:
        return data;
    }
  };

  const counts = {
    unpaid: filterData(invoicesData, "unpaid").length,
    draft: filterData(invoicesData, "draft").length,
    overdue: filterData(invoicesData, "overdue").length,
    paid: filterData(invoicesData, "paid").length,
    canceled: filterData(invoicesData, "canceled").length,
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "number",
      headerName: "NUMÉRO",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "ENTREPRISE",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "issuedAt",
      headerName: "CRÉÉ LE",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: "ÉCHÉANCE",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "totalWithTax",
      headerName: "TOTAL TTC",
      type: "string",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "paymentStatus",
      headerName: "STATUT",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row }: { row: Invoice }) => {
        let payment;
        let inputColor;
        const status = row.paymentStatus;
        
        switch (status) {
          case "DRAFT":
            payment = "Brouillon";
            inputColor = colors.grey[700];
            break;
          case "CANCELLED":
            payment = "Annulée";
            inputColor = colors.redAccent[700];
            break;
          case "UNPAID":
            payment = "Non payée";
            inputColor = colors.grey[500];
            break;
          case "LATE":
            payment = "En retard";
            inputColor = colors.redAccent[400];
            break;
          case "PAID":
            payment = "Payée";
            inputColor = colors.greenAccent[700];
            break;
          default:
            payment = "Erreur";
            inputColor = colors.grey[500];
            break;
        }
        
        return (
          <Button
            variant="contained"
            size="small"
            style={{
              width: "60%",
              margin: "0 auto",
              padding: "5px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: inputColor,
              textTransform: "none",
            }}
            onClick={() => handleRowStatusClick(row)}
          >
            <Typography color={colors.grey[100]}>{payment}</Typography>
          </Button>
        );
      },
    },
    {
      field: "apercu",
      headerName: "APERÇU",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: ({ row }: { row: Invoice }) => {
        return (
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: colors.grey[700],
              color: colors.grey[100],
              textTransform: "none",
            }}
            onClick={() => handleRowPreviewInvoiceClick(row)}
          >
            <InsertDriveFileIcon sx={{ mr: "10px" }} />
            Ouvrir
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    fetchInvoices();
  }, [store.invoice]);

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box m="20px">
      <Dashboard
        title="Factures"
        subtitle="Retrouvez toutes vos factures"
        element={
          <Button href="/invoice/edit" variant="contained" color="secondary">
            <AddIcon />
            Ajouter
          </Button>
        }
      />
      <Paper elevation={3} sx={{ marginBottom: 2 }}>
        <Tabs
          value={filter}
          onChange={handleFilterChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label={renderTabLabel("Tout", invoicesData.length)}
            value="all"
          />
          <Tab
            label={renderTabLabel("Non payées", counts.unpaid)}
            value="unpaid"
          />
          <Tab
            label={renderTabLabel("Brouillons", counts.draft)}
            value="draft"
          />
          <Tab
            label={renderTabLabel("En retard", counts.overdue)}
            value="overdue"
          />
          <Tab label={renderTabLabel("Payées", counts.paid)} value="paid" />
          <Tab
            label={renderTabLabel("Annulées", counts.canceled)}
            value="canceled"
          />
        </Tabs>
      </Paper>
      {isLoading ? (
        <CircularProgress />
      ) : filterData(invoicesData, filter).length === 0 ? (
        <Typography variant="h6">Aucune facture disponible</Typography>
      ) : (
        <Table Data={filterData(invoicesData, filter)} columns={columns} />
      )}
      <InvoiceStatusModal
        open={isStatusOpen}
        handleClose={() => setIsStatusOpen(false)}
        handlePaidStatus={handlePaidStatus}
        selectedInvoice={selectedInvoiceData}
        fetchInvoicesCallback={fetchInvoices}
      />
      <PreviewInvoiceModal
        open={isPreviewOpen}
        handleClose={() => setIsPreviewOpen(false)}
        selectedInvoice={selectedInvoiceData}
      />
      <ToastContainer />
    </Box>
  );
};

export default Invoices;
