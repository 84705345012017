import React from "react";
import { useEffect, useState } from "react";
import "./editInvoice.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Select,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import { textFieldStyleModal } from "../../../components/style/componentStyle";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { formatInputDate, formatUserDate } from "../../../utils/formatDate";
import { toast } from "react-toastify";
import { Customer, InvoiceLine, Invoice, checkoutSchema } from "./types.ts";
import EditInvoiceSidebar from "./EditInvoiceSidebar.tsx";
import { TVA_RATES, getDefaultTvaRate } from "../../../constants/TvaRate.ts";
import EditLocationForm from "./EditLocationForm.tsx";
import LocationLine from "./LocationLine.tsx";


// Ajouter ces interfaces au début du fichier
interface LocationCommercial {
  id: number;
  companyName: string;
  contactEmail: string;
  officeNumber: string;
  address: string;
}


interface LocationEmplacement {
  id: number;
  ref: string;
  name: string;
  city: string;
  startDate?: string;
  endDate?: string;
  advertisingLocationId?: number | null;
  isTemporary?: boolean;
}

interface LocationDateRange {
  startDate: string;
  endDate: string;
}

interface LocationData {
  commercial?: LocationCommercial | null;
  emplacement?: LocationEmplacement | null;
  dateRange?: LocationDateRange;
}

interface Location {
  id: number;
  name: string;
  ref: string;
  city: string;
  locationNumber: number;
  totalLocations: number;
}

const EditInvoice = () => {
  // Récupération du store global
  const store = useGlobalStore();

  const [documentType, setDocumentType] = useState<string>("facture");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // État pour stocker la liste des clients
  const [customers, setCustomers] = useState<Customer[]>([]);

  // État pour stocker les taux de taxe
  /* const [taxRates, setTaxRates] = useState<{ id: number; rate: number }[]>([]); // Typage explicite pour taxRates */

  // Stocker les taxes
  /* const [selectedTaxRates, setSelectedTaxRates] = useState<{ [key: number]: number | null }>({}); */

  // Const TVA

  const taxRates = TVA_RATES;

  // État pour gérer le chargement des données
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // État pour le numéro de la facture
  const [number, setNumber] = useState<string>("");

  // État pour le client sélectionné
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  // État pour la date de facturation
  const [issuedAt, setIssuedAtDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  // État pour les conditions de paiement (nombre de jours)
  const [paymentDaysCondition, setPaymentDaysCondition] = useState<string>("");

  // État pour la date d'échéance
  const [dueDate, setDueDate] = useState<string | null>(null);

  // État pour le message de la facture
  const [message, setMessage] = useState<string>(
    "Cevep vous remercie pour votre confiance."
  );

  // Récupération de l'ID de la facture à partir des paramètres de l'URL
  const { id } = useParams<{ id: string }>(); // Typage des paramètres

  // État pour la longueur du message
  const [messageLength, setMessageLength] = useState<number>(0);

  // État pour le numéro de facture généré
  const [generatedInvoiceNumber, setGeneratedInvoiceNumber] =
    useState<string>("");

  // On set les lignes de facture par défaut

  const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([
    {
      description: "",
      quantity: 1,
      price: 0,
      position: 1,
      taxRateId: getDefaultTvaRate().id,
      taxRateValue: getDefaultTvaRate().rate,
      product: "",
      product_id: null,
      emplacement: {
        id: 0,
        ref: '',
        name: '',
        city: '',
        startDate: '',
        endDate: '',
        advertisingLocationId: undefined,
        isTemporary: false
      }
    },
  ]);

  const initialValues = {
    number: generatedInvoiceNumber || number,
    customerId: selectedCustomer ? selectedCustomer.id : "",
    issuedAt: issuedAt,
    message: message,
    paymentDaysCondition: paymentDaysCondition || 0,
    lines: invoiceLines,
  };

  // État pour stocker les paramètres de la sidebar
  const [sidebarSettings, setSidebarSettings] = useState({
    invoiceType: "Simple",
    frequency: "",
    duration: null,
    sendMethod: "Créer seulement",
    durationType: "Limité",
    depositAmount: 0,
    installments: 1,
    hasDeposit: false,
    hasInstallments: false,
    paymentMethod: "virement",
  });

  // Ajouter un état pour stocker l'emplacement temporaire créé
  const [temporaryLocation, setTemporaryLocation] = useState<{
    id: number;
    ref: string;
    name: string;
    city: string;
    advertisingLocationId: number;
  } | null>(null);

  const addNewLine = () => {
    setInvoiceLines((prevLines) => [
      ...prevLines,
      {
        description: temporaryLocation 
          ? `Location ${temporaryLocation.name} - ${temporaryLocation.city}`
          : "",
        quantity: 1,
        price: 0,
        position: prevLines.length + 1,
        taxRateId: getDefaultTvaRate().id,
        taxRateValue: getDefaultTvaRate().rate,
        priceTotal: 0,
        product: temporaryLocation?.name || "",
        product_id: temporaryLocation?.advertisingLocationId || null,
        emplacement: temporaryLocation ? {
          id: temporaryLocation.id,
          ref: temporaryLocation.ref,
          name: temporaryLocation.name,
          city: temporaryLocation.city,
          advertisingLocationId: temporaryLocation.advertisingLocationId,
          startDate: '',
          endDate: '',
          isTemporary: temporaryLocation.advertisingLocationId < 0
        } : {
          id: 0,
          ref: '',
          name: '',
          city: '',
          startDate: '',
          endDate: '',
          advertisingLocationId: undefined,
          isTemporary: false
        }
      },
    ]);
  };

  // Nouvelle fonction pour supprimer une ligne de facture
  const removeLine = (index) => {
    setInvoiceLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  // On gere la soumission du formulaire

  const handleValidation = (values) => {
    const errors: string[] = [];

    // Vérifiez si un client est sélectionné
    if (!selectedCustomer) {
      errors.push("Vous devez sélectionner un client.");
    }

    // Vérifiez si le numéro de facture est renseigné
    if (!values.number) {
      errors.push("Le numéro de facture est requis.");
    }

    // Vérifiez si la date de facturation est renseignée
    if (!values.issuedAt) {
      errors.push("La date de facturation est requise.");
    }

    // Si des erreurs sont trouvées, afficher un toast et retourner false
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return false; // Indiquer qu'il y a une erreur
    }

    // Vérifiez que chaque ligne de facture a un taux de TVA
    for (const line of values.lines) {
      if (!line.taxRateId) {
        errors.push(
          `La ligne "${line.description}" doit avoir un taux de TVA.`
        );
      }
    }

    return true; // Indiquer que la validation est réussie
  };

  // Modifier la déclaration de l'état pour utiliser l'interface
  const [locationData, setLocationData] = useState<LocationData | null>(null);

  // Modifier handleInvoiceSubmit pour inclure les données de location
  const handleInvoiceSubmit = (data) => {
    // Formater les données selon la structure qui fonctionne en édition
    const formattedData = {
      number: generatedInvoiceNumber || number,
      customerId: selectedCustomer ? selectedCustomer.id : "",
      issuedAt: issuedAt,
      message: message,
      paymentDaysCondition: parseInt(paymentDaysCondition) || 0,
      lines: invoiceLines.map((line) => ({
        description: line.description,
        quantity: parseInt(line.quantity),
        price: parseInt(line.price),
        position: line.position,
        taxRateId: line.taxRateId,
        emplacement: {
          ...line.emplacement,
          advertisingLocationId: line.product_id,
          isTemporary: line.product_id ? line.product_id < 0 : false
        },
        startDate: line.emplacement?.startDate,
        endDate: line.emplacement?.endDate
      })),
      paiement: data.invoice_type === "simple" ? {
        payment_method: data.payment_method || "virement",
        discount_amount: data.discount_amount ? parseInt(data.discount_amount * 100) : 0,
        installments: parseInt(data.installments) || 1,
        paymentMethodDescription: "Paiement par virement",
        invoice_type: "simple"
      } : data.invoice_type === "recurring" ? {
        payment_method: data.payment_method || "virement",
        frequency: data.frequency || null,
        duration: parseInt(data.duration) || null,
        is_infinite: data.is_infinite || false,
        invoice_type: "recurring"
      } : {
        invoice_type: "location"
      },
      location: locationData ? {
        commercial: locationData.commercial,
        emplacement: locationData.emplacement,
        dateRange: {
          startDate: issuedAt,
          endDate: locationData.dateRange?.endDate || issuedAt
        }
      } : null
    };

    console.log("Données formatées pour création avec location complète:", formattedData);
    handleSubmit(formattedData);
  };

  const handleSubmit = async (values) => {
    if (!handleValidation(values)) {
      return;
    }
    
    try {
      // Récupérer la première ligne pour les dates
      const firstLine = invoiceLines[0];
      
      const formattedData = {
        ...values,
        lines: invoiceLines.map(line => ({
          ...line,
          quantity: parseInt(line.quantity),
          price: parseInt(line.price),
          emplacement: {
            ...line.emplacement,
            advertisingLocationId: line.product_id,
            isTemporary: line.product_id ? line.product_id < 0 : false
          }
        })),
        location: locationData ? {
          commercial: locationData.commercial,
          emplacement: locationData.emplacement,
          dateRange: {
            startDate: firstLine?.emplacement?.startDate || issuedAt,
            endDate: firstLine?.emplacement?.endDate || issuedAt
          }
        } : null
      };

      console.log("Données formatées pour création:", formattedData);
      
      let result;
      if (id) {
        result = await store.invoice.putInvoice(id, formattedData);
        if (result.success) {
          toast.success("La facture a bien été mise à jour.");
        }
      } else {
        result = await store.invoice.postInvoice(formattedData);
        if (result.success) {
          toast.success("La facture a bien été créée et enregistrée dans les brouillons.");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      toast.error(error.response?.data?.message || "Une erreur est survenue lors de l'envoi des données.");
    }
  };

  const handleGenerateInvoiceNumber = async () => {
    if (!id) {
      // Vérifiez si l'ID n'est pas présent dans l'URL
      const format = "YEAR/COUNT"; // Le format que vous souhaitez utiliser
      const prefix = "FA"; // Le préfixe que vous souhaitez utiliser

      const result = await store.invoice.generateInvoiceNumber(format, prefix);
      console.log("Résultat de la génération du numéro de facture:", result);
      if (result.success) {
        const invoiceNumber = result.data[0]; // Accéder au premier élément du tableau Proxy
        setGeneratedInvoiceNumber(invoiceNumber);
      } else {
        toast.error("Erreur lors de la génération du numéro de facture");
      }
    }
  };

  useEffect(() => {
    handleGenerateInvoiceNumber(); // Appeler la méthode pour générer un numéro de facture si ID n'est pas présent
  }, [id]);

  const fetchInvoiceData = async (invoiceId) => {
    setIsLoading(true);
    try {
      const result = await store.invoice.getInvoiceById(invoiceId);

      if (result.success) {
        const invoice = result.data;
        console.log("Invoice data:", invoice);

        if (invoice && typeof invoice === "object" && !Array.isArray(invoice)) {
          if (Array.isArray(invoice.invoiceLines)) {
            setInvoiceLines(
              invoice.invoiceLines.map((line, index) => {
                // Extraire l'ID de l'emplacement de l'URL
                const advertisingLocationId = line.historyAdvertisingLocation?.AdvertisingLocation
                  ? parseInt(line.historyAdvertisingLocation.AdvertisingLocation.split('/').pop())
                  : null;

                // Trouver le produit correspondant
                const product = products.find(p => p.id === line.product_id);
                
                // Trouver l'emplacement correspondant
                const location = product?.AdvertisingLocation?.find(loc => 
                  loc.id === advertisingLocationId
                );

                const taxRate = line.taxRate ? 
                  TVA_RATES.find(rate => rate.id === line.taxRate) || getDefaultTvaRate() :
                  getDefaultTvaRate();

                console.log("Processing line:", {
                  advertisingLocationId,
                  product,
                  location,
                  historyAdvertisingLocation: line.historyAdvertisingLocation
                }); // Pour debug

                return {
                  id: line.id || 0,
                  description: line.description || "",
                  quantity: line.quantity || 0,
                  price: line.price || 0,
                  position: index + 1,
                  taxRateId: taxRate.id,
                  taxRateValue: taxRate.rate,
                  product: line.product?.["@id"] || "",
                  product_id: advertisingLocationId, // Utiliser l'ID extrait ici
                  emplacement: {
                    id: line.product_id || 0,
                    ref: product?.ref || "",
                    name: product?.name || line.description || "",
                    city: product?.productLocalisation?.city || "",
                    startDate: line.historyAdvertisingLocation?.startDate ? 
                      new Date(line.historyAdvertisingLocation.startDate).toISOString().split('T')[0] : '',
                    endDate: line.historyAdvertisingLocation?.endDate ? 
                      new Date(line.historyAdvertisingLocation.endDate).toISOString().split('T')[0] : '',
                    advertisingLocationId: advertisingLocationId,
                    isTemporary: false
                  }
                };
              })
            );
          }

          console.log("Données de la facture:", invoice);
          // Gérer les autres propriétés
          setNumber(invoice.number || "");
          setIssuedAtDate(formatInputDate(new Date(invoice.issuedAt)));
          setMessage(invoice.message || "");
          setPaymentDaysCondition(invoice.paymentDaysCondition || 0);

          // Modifier cette partie pour s'assurer que le client est correctement défini
          if (invoice.customer) {
            const customerData = {
              id: invoice.customer.id,
              companyName: invoice.customer.companyName,
              address: invoice.customer.address,
              // Ajouter d'autres propriétés du client si nécessaire
            };
            setSelectedCustomer(customerData);
            
            // Ajouter le client à la liste des clients s'il n'y est pas déjà
            setCustomers(prevCustomers => {
              const customerExists = prevCustomers.some(c => c.id === customerData.id);
              if (!customerExists) {
                return [...prevCustomers, customerData];
              }
              return prevCustomers;
            });
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de la facture :", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Appel de la fonction de récupération de données lorsque l'ID est présent
  useEffect(() => {
    if (id) {
      fetchInvoiceData(id); // Charger les données si un ID est passé dans l'URL
    }
  }, [id]);

  // Recuperation des données

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const result = await store.customer.getCustomers();

      if (result.success) {
        const customersArray = Array.isArray(result.data)
          ? result.data
          : [result.data];

        // Conserver le client sélectionné actuel
        const currentSelectedCustomer = selectedCustomer;
        
        setCustomers(customersArray);

        // Si on est en mode édition et qu'on a un client sélectionné, le conserver
        if (id && currentSelectedCustomer) {
          const customerInList = customersArray.find(c => c.id === currentSelectedCustomer.id);
          if (customerInList) {
            setSelectedCustomer(customerInList);
          }
        }
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  /* const fetchTaxRate = async () => {
    try {
      const result = await store.taxeRate.getTaxeRate();

      if (result.success) {
        // Extraire les taux de taxes de result.data
        const taxRates = result.data.map((taxRate) => ({
          id: taxRate.id,
          rate: taxRate.amount,
        }));

        setTaxRates(taxRates);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  }; */

  // Récupérer les données lorsque l'ID est présent
  useEffect(() => {
    if (id) {
      fetchInvoiceData(id);
    }
  }, [id]);

  // Récupérer la longueur du message
  useEffect(() => {
    setMessageLength(message.length);
  }, [message]);

  // Initialiser la date de facturation
  useEffect(() => {
    const today = new Date();
    const dateToString = formatInputDate(today);
    setIssuedAtDate(dateToString);
  }, []);

  // Calculer la date d'échéance
  useEffect(() => {
    const newDate = new Date(issuedAt);
    newDate.setDate(newDate.getDate() + Number(paymentDaysCondition));
    setDueDate(formatUserDate(newDate));
    
    // Mettre à jour locationData.dateRange si nécessaire
    setLocationData(prev => {
      if (!prev) return prev;
      
      return {
        ...prev,
        dateRange: {
          ...prev.dateRange,
          startDate: issuedAt,
          endDate: prev.dateRange?.endDate || issuedAt
        }
      };
    });
  }, [issuedAt, paymentDaysCondition]);

  // Récupérer les taux de taxe
  useEffect(() => {
    if (taxRates.length === 0) {
      fetchTaxRate();
    }
  }, [taxRates]);

  // Récupérer les clients
  useEffect(() => {
    const loadCustomers = async () => {
      // Charger la liste complète des clients dans tous les cas
      if (customers.length === 0) {
        await fetchCustomers();
      }
    };
    loadCustomers();
  }, [customers.length]); // Supprimer la dépendance à 'id'

  // Fonction pour gérer le changement des jours de paiement
  const handleDateDueChange = (event: SelectChangeEvent<string | number>) => {
    setPaymentDaysCondition(event.target.value as string);
  };

  // Fonction pour gérer le changement de la date d'émission
  const handleIssuedAt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuedAtDate(event.target.value);
    
    // Mettre à jour locationData avec la nouvelle date
    setLocationData(prev => {
      if (!prev) return prev;
      
      return {
        ...prev,
        dateRange: {
          ...prev.dateRange,
          startDate: event.target.value, // Utiliser la nouvelle date comme date de début
          endDate: prev.dateRange?.endDate || event.target.value // Garder la date de fin existante ou utiliser la nouvelle date
        }
      };
    });
  };

  // Fonction pour gérer le changement de client
  const handleCustomerChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedCustomerId = event.target.value as string;
    const customer = customers.find((c) => c.id === selectedCustomerId);
    if (customer) {
      setSelectedCustomer(customer);
    } else {
      console.error("Aucun client trouvé avec l'ID:", selectedCustomerId);
      setSelectedCustomer(null);
    }
  };

  // Réinitialiser la sélection du client
  const resetSelection = () => {
    setSelectedCustomer(null);
  };

  // Gérer les changements dans les lignes de facture
  const handleLineChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | { value: unknown }>
      | SelectChangeEvent<number>
      | { target: { value: any; fullLocationData?: any } },
    index: number,
    field: string
  ) => {
    const newInvoiceLines = [...invoiceLines];
    
    if (field === "product_id") {
      const locationData = event.target.value;
      const fullLocationData = event.target.fullLocationData;
      console.log('Données reçues dans EditInvoice:', locationData, fullLocationData);
      
      newInvoiceLines[index] = {
        ...newInvoiceLines[index],
        description: locationData.description || '',
        product_id: locationData.advertisingLocationId,
        emplacement: {
          id: locationData.id,
          ref: locationData.ref,
          name: locationData.name,
          city: locationData.city,
          startDate: newInvoiceLines[index].emplacement?.startDate || '',
          endDate: newInvoiceLines[index].emplacement?.endDate || '',
          advertisingLocationId: locationData.advertisingLocationId,
          isTemporary: locationData.advertisingLocationId < 0
        }
      };

      // Mettre à jour locationData avec les données complètes
      if (fullLocationData) {
        setLocationData(fullLocationData);
      }
    } else if (field === "startDate" || field === "endDate") {
      if (!newInvoiceLines[index].emplacement) {
        newInvoiceLines[index].emplacement = {
          id: newInvoiceLines[index].product_id || 0,
          ref: '',
          name: '',
          city: '',
          startDate: '',
          endDate: ''
        };
      }
      newInvoiceLines[index].emplacement = {
        ...newInvoiceLines[index].emplacement,
        [field]: event.target.value
      };

      // Mettre à jour locationData.dateRange
      if (locationData) {
        setLocationData(prev => ({
          ...prev,
          dateRange: {
            ...prev?.dateRange,
            [field]: event.target.value
          }
        }));
      }
    } else {
      // Reste de la logique existante pour les autres champs
      if (field === "price") {
        const valueAsFloat = parseFloat(event.target.value as string);
        const valueAsCentimes = Math.round(valueAsFloat * 100);
        newInvoiceLines[index][field] = !isNaN(valueAsCentimes)
          ? valueAsCentimes
          : 0;
      } else if (field === "quantity") {
        const valueAsInt = parseInt(event.target.value as string, 10);
        newInvoiceLines[index][field] = !isNaN(valueAsInt) ? valueAsInt : 0;
      } else if (field === "taxRateId") {
        const taxRateId = event.target.value as string;
        const selectedTaxRate = taxRates.find((rate) => rate.id === taxRateId);
        if (selectedTaxRate) {
          newInvoiceLines[index].taxRateId = selectedTaxRate.id;
          newInvoiceLines[index].taxRateValue = selectedTaxRate.rate;
        }
      } else {
        newInvoiceLines[index][field] = event.target.value as string;
      }
    }
    
    console.log('Ligne mise à jour:', newInvoiceLines[index]);
    setInvoiceLines(newInvoiceLines);
  };

  // Calculer le total de la facture
  const calculateTotal = () => {
    return invoiceLines.reduce((total, line) => {
      const taxRateDecimal = line.taxRateValue / 100;
      const priceInEuros = line.price / 100; // Convert price from centimes to euros
      const lineTotal = line.quantity * priceInEuros * (1 + taxRateDecimal);
      return total + lineTotal;
    }, 0);
  };

  const handleTTCChange = (e, index) => {
    const prixTTC = parseFloat(e.target.value);
    const taxRateId = invoiceLines[index].taxRateId; // Récupérer l'ID du taux de taxe
    const taxRate = taxRates.find((rate) => rate.id === taxRateId); // Trouver le taux de taxe correspondant
    if (taxRate) {
      const taxRateDecimal = taxRate.rate / 100; // Utiliser le taux de taxe trouvé
      const prixHT = prixTTC / (1 + taxRateDecimal);
      handleLineChange({ target: { value: prixHT } }, index, "price");
    }
  };

  // Fonction pour afficher les prix en euros (avec conversion depuis centimes)
  const displayPriceInEuros = (priceInCentimes) => {
    return (priceInCentimes / 100).toFixed(2); // Convertir centimes en euros
  };

  // Ajouter une fonction pour mettre à jour les données de location
  const handleLocationDataUpdate = (data: Partial<LocationData>) => {
    console.log("Données de location reçues:", data);
    
    setLocationData(data);

    if (data.emplacement?.isTemporary && data.dateRange) {
      const tempId = data.emplacement.advertisingLocationId || -Math.floor(Math.random() * 1000);
      
      setTemporaryLocation({
        id: data.emplacement.id,
        ref: data.emplacement.ref,
        name: data.emplacement.name,
        city: data.emplacement.city,
        advertisingLocationId: tempId,
        startDate: data.dateRange.startDate,
        endDate: data.dateRange.endDate
      });

      // Mettre à jour localProducts avec le nouvel emplacement temporaire
      setLocalProducts(prevProducts => 
        prevProducts.map(product => {
          if (product.ref === data.emplacement.ref) {
            return {
              ...product,
              AdvertisingLocation: [
                ...product.AdvertisingLocation,
                {
                  id: tempId,
                  name: data.emplacement.name,
                  city: data.emplacement.city,
                  isTemporary: true
                }
              ]
            };
          }
          return product;
        })
      );

      // Mettre à jour les lignes de facture
      setInvoiceLines(prevLines => {
        const emptyLineIndex = prevLines.findIndex(line => !line.description);
        const newLine = {
          description: `Location ${data.emplacement.name} - ${data.emplacement.city}`,
          quantity: 1,
          price: 0,
          position: invoiceLines.length + 1,
          taxRateId: getDefaultTvaRate().id,
          taxRateValue: getDefaultTvaRate().rate,
          product: data.emplacement.name,
          product_id: tempId,
          emplacement: {
            id: data.emplacement.id,
            ref: data.emplacement.ref,
            name: data.emplacement.name,
            city: data.emplacement.city,
            startDate: data.dateRange.startDate,
            endDate: data.dateRange.endDate,
            advertisingLocationId: tempId,
            isTemporary: true
          }
        };

        if (emptyLineIndex >= 0) {
          const updatedLines = [...prevLines];
          updatedLines[emptyLineIndex] = newLine;
          return updatedLines;
        } else {
          return [...prevLines, newLine];
        }
      });
    }

    // Mettre à jour le client sélectionné si présent
    if (data.commercial) {
      const commercialData = {
        id: data.commercial.id,
        companyName: data.commercial.companyName,
        contactEmail: data.commercial.contactEmail,
        officeNumber: data.commercial.officeNumber,
        address: data.commercial.address
      };
      setSelectedCustomer(commercialData);
    }
  };

  // Ajouter cet état pour stocker les emplacements formatés
  const [allLocations, setAllLocations] = useState<Location[]>([]);
  
  // Ajouter cet état pour stocker les produits
  const [products, setProducts] = useState<Product[]>([]);

  // Ajouter cet état au début du composant, avec les autres états
  const [localProducts, setLocalProducts] = useState<Product[]>([]);

  // Ajouter cette fonction pour formater les emplacements
  const formatLocations = (products: Product[]) => {
    const locations = products.flatMap(product => 
      product.AdvertisingLocation.map((location, index) => ({
        id: location.id,
        name: product.name,
        ref: product.ref,
        city: product.productLocalisation?.city || '',
        locationNumber: index + 1,
        totalLocations: product.AdvertisingLocation.length
      }))
    );
    setAllLocations(locations);
  };

  // Ajouter cette fonction pour récupérer les produits
  const fetchProducts = async () => {
    try {
      const result = await store.product.getProducts();
      console.log("Product recu", result.data);
      if (result.success) {
        setProducts(result.data);
        setLocalProducts(result.data); // Initialiser localProducts avec les mêmes données
        formatLocations(result.data);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des produits:", error);
    }
  };

  // Ajouter cet useEffect pour charger les produits
  useEffect(() => {
    fetchProducts();
  }, []);

  // Ajouter un bouton spécifique pour ajouter une ligne avec l'emplacement temporaire
  const addNewLineWithTemporaryLocation = () => {
    if (!temporaryLocation) {
      toast.error("Aucun emplacement temporaire disponible");
      return;
    }
    addNewLine();
  };

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <EditInvoiceSidebar
        onSubmit={handleInvoiceSubmit}
        settings={sidebarSettings}
        setDocumentType={setDocumentType}
      />

      {documentType === "facture" ? (
        <>
          <Box m="20px">
            <Dashboard
              title={"Factures"}
              subtitle={"Retrouvé toutes vos factures"}
            />
          </Box>
          <div className="invoice-container">
            <Formik
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                dirty,
              }) => (
                <form id="monFormulaire" onSubmit={handleSubmit}>
                  <h1>Facture</h1>
                  <div>
                    <Box m={2}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Box>
                            <strong>Numéro de Facture:</strong>
                          </Box>
                          <Box>
                            <TextField
                              sx={textFieldStyleModal}
                              value={values.number}
                              onChange={handleChange}
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <strong>Date de facturation:</strong>
                          </Box>
                          <Box>
                            <TextField
                              sx={textFieldStyleModal}
                              type="date"
                              name="issuedAt"
                              value={values.issuedAt}
                              onChange={handleIssuedAt}
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <strong>Conditions de paiement:</strong>
                          </Box>
                          <Box>
                            <Select
                              value={values.paymentDaysCondition}
                              displayEmpty
                              sx={textFieldStyleModal}
                              fullWidth
                              onChange={handleDateDueChange}
                              name="paymentDaysCondition"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={0}>0</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={30}>30</MenuItem>
                              <MenuItem value={60}>60</MenuItem>
                              <MenuItem value={90}>90</MenuItem>
                            </Select>
                          </Box>
                          <Box>
                            <strong>Date d'émission de la facture :</strong>
                          </Box>
                          <Box>
                            <TextField
                              sx={textFieldStyleModal}
                              value={dueDate}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={6}>
                          <Box>
                            <strong>Message ({messageLength} /3000):</strong>
                          </Box>
                          <Box>
                            <TextField
                              sx={textFieldStyleModal}
                              multiline
                              rows={4}
                              variant="outlined"
                              value={values.message}
                              onChange={(e) => {
                                setMessageLength(e.target.value.length);
                                setMessage(e.target.value);
                              }}
                              size="small"
                              inputProps={{ maxLength: 3000 }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <hr />
                  </div>
                  <div className="invoice-header">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          {!selectedCustomer && (
                            <Select
                              value={
                                selectedCustomer ? selectedCustomer.id : ""
                              }
                              onChange={handleCustomerChange}
                              displayEmpty
                              sx={textFieldStyleModal}
                              inputProps={{ "aria-label": "Without label" }}
                              error={
                                touched.customerSelect &&
                                !!errors.customerSelect
                              }
                              helpertext={
                                touched.customerSelect && errors.customerSelect
                              }
                            >
                              <MenuItem value="" disabled>
                                Sélectionner un client
                              </MenuItem>
                              {customers.map((customer) => (
                                <MenuItem key={customer.id} value={customer.id}>
                                  {customer.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {selectedCustomer && (
                            <div>
                              <h3>Informations sur le Client</h3>
                              <Button onClick={resetSelection}>
                                Changer de client
                              </Button>
                              <p>Nom: {selectedCustomer.companyName}</p>
                              <p>Adresse: {selectedCustomer.address}</p>
                              {/* Affichez d'autres informations sur le client ici */}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <p>
                              <strong>C.E.V.E.P</strong>
                            </p>
                            <p>Adresse: 15 rue de valencienne</p>
                            <p>59000, Lille</p>
                            {/* Affichez d'autres informations sur le client ici */}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <table className="invoice-table">
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Description</th>
                        <th>Quantité</th>
                        <th>Prix Unitaire</th>
                        <th>TVA</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceLines.map((line, index) => (
                        <tr key={index}>
                          <td>
                            <LocationLine 
                              products={localProducts}
                              onLocationSelect={(event) => handleLineChange(
                                event,
                                index,
                                "product_id"
                              )}
                              selectedLocationId={line.product_id}
                              setDocumentType={setDocumentType}
                              selectedCustomer={selectedCustomer}
                            />
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              type="date"
                              size="small"
                              value={line.emplacement?.startDate || ''}
                              onChange={(e) => handleLineChange(
                                { target: { value: e.target.value } },
                                index,
                                "startDate"
                              )}
                            />
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              type="date"
                              size="small"
                              value={line.emplacement?.endDate || ''}
                              onChange={(e) => handleLineChange(
                                { target: { value: e.target.value } },
                                index,
                                "endDate"
                              )}
                            />
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              variant="outlined"
                              value={line.description}
                              onChange={(e) =>
                                handleLineChange(e, index, "description")
                              }
                              size="small"
                            />
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              type="number"
                              variant="outlined"
                              value={line.quantity}
                              inputProps={{ min: 1, max: 1 }}
                              onChange={(e) =>
                                handleLineChange(e, index, "quantity")
                              }
                              size="small"
                            />
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              type="number"
                              variant="outlined"
                              value={displayPriceInEuros(line.price)}
                              onChange={(e) =>
                                handleLineChange(e, index, "price")
                              }
                              size="small"
                            />
                          </td>
                          <td>
                            <Select
                              sx={textFieldStyleModal}
                              size="small"
                              /* labelId="demo-select-small-label"
                              id="demo-select-small" */
                              value={line.taxRateId}
                              onChange={(e) =>
                                handleLineChange(e, index, "taxRateId")
                              }
                              label="Taux de TVA"
                            >
                              {taxRates.map((rate) => (
                                <MenuItem key={rate.id} value={rate.id}>
                                  {`${rate.rate}%`}
                                </MenuItem>
                              ))}
                            </Select>
                          </td>
                          <td>
                            <TextField
                              sx={textFieldStyleModal}
                              type="number"
                              variant="outlined"
                              value={(
                                (line.price / 100) * // convert centimes to euros
                                line.quantity *
                                (1 + line.taxRateValue / 100)
                              ).toFixed(2)}
                              onChange={(e) => handleTTCChange(e, index)}
                              size="small"
                            />
                          </td>
                          <td>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => removeLine(index)} // Appeler la fonction pour supprimer la ligne
                            >
                              <DeleteIcon />
                            </Button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5">
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box marginTop={"20px"}>
                              <Button variant="contained" onClick={addNewLine}>
                                Ajouter une ligne simple
                              </Button>
                            </Box>
                            {temporaryLocation && (
                              <Box marginTop={"20px"}>
                                <Button 
                                  variant="contained" 
                                  color="secondary"
                                  onClick={addNewLineWithTemporaryLocation}
                                >
                                  Ajouter une ligne avec {temporaryLocation.name}
                                </Button>
                              </Box>
                            )}
                            <Box marginTop={"20px"}>
                              <Button variant="contained" onClick={() => setDocumentType("location")}>
                                Créer un nouvel emplacement
                              </Button>
                            </Box>
                          </Box>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Box className="invoice-total">
                    <strong>Total TTC:</strong> {calculateTotal().toFixed(2)} €
                  </Box>
                  <Box className="invoice-footer">
                    <p>Merci pour votre entreprise !</p>
                  </Box>
                </form>
              )}
            </Formik>
          </div>
        </>
      ) : documentType === "location" ? (
        <EditLocationForm
          title="Location"
          subtitle="Créer un contrat de location"
          onLocationDataUpdate={handleLocationDataUpdate}
          setDocumentType={setDocumentType}
          preselectedCommercial={selectedCustomer ? selectedCustomer.id.toString() : null}
        />
      ) : null}
    </div>
  );
};

export default EditInvoice;