import "./login.css";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useGlobalStore } from "../../store/globalStore.tsx";
import chien from "../../img/login/chien.webp";
import { tokens } from "../../theme";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const store = useGlobalStore();
  const navigate = useNavigate();
  console.log(store.token);
  {
    if (store.token) {
      navigate("/dashboard");
    }
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeEmail = ({ currentTarget }) => {
    setEmail(currentTarget.value);
  };

  const onChangePassword = ({ currentTarget }) => {
    setPassword(currentTarget.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isConnect = store.authService.connexion(email, password);
    isConnect.then((res) => {
      if (res === true) {
        navigate("/s");
      } else {
        alert("Email ou mot de passe incorrect");
      }
    });
  };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", width: "50%" }}>
        <img
          src={chien}
          alt="chien"          
          style={{ 
            width: "auto", 
            height: "auto", 
            padding: "20px",
            margin: "auto",
            borderRadius: "40px"
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h1">Bienvenue sur CEVEP</Typography>
          <Box>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={onChangeEmail}
              sx={{ width: "100%", marginTop: "20px" }}
            />
            <TextField
              type="password"
              id="outlined-basic"
              label="Password"
              variant="outlined"
              value={password}
              onChange={onChangePassword}
              sx={{ width: "100%", marginTop: "20px" }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
              <Button
                variant="contained"
                onClick={onSubmit}
                type="submit"
                color="secondary"
                sx={{ 
                  width: "100%", 
                  borderRadius: "20px",
                  color: "#ffffff"
                }}
              >
                Connexion
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Login);
