import * as React from 'react';
import { Box, Typography, Select, MenuItem, CircularProgress } from '@mui/material';

interface Commercial {
  id: number;
  companyName: string;
  contactEmail: string;
  officeNumber: string;
  address: string;
}

interface StepperComercialProps {
  onSelectCommercial: (commercialId: string) => void;
  selectedCommercial: string | null;
  commercials: Commercial[];
  isLoading: boolean;
}

export default function StepperComercial({ 
  onSelectCommercial, 
  selectedCommercial,
  commercials,
  isLoading 
}: StepperComercialProps) {
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <Typography variant="h6" gutterBottom>
        Sélection du Commercial
      </Typography>
      <Select 
        fullWidth 
        value={selectedCommercial || ""}
        onChange={(e) => {
          console.log("Selected Commercial:", e.target.value);
          onSelectCommercial(e.target.value.toString());
        }}
        sx={{ minWidth: 200 }}
        disabled={!!selectedCommercial}
      >
        <MenuItem value="">Sélectionnez un commercial</MenuItem>
        {commercials.map((commercial) => (
          <MenuItem key={commercial.id} value={commercial.id.toString()}>
            {`${commercial.companyName} - ${commercial.contactEmail} ${commercial.officeNumber ? `(${commercial.officeNumber})` : ''}`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
