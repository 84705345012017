import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";
import GlobalStore from "../../globalStore";
import { toast } from "react-toastify";

// Define the InvoiceResponse type
interface InvoiceResponse {
  success: boolean;
  data?: any;
  error?: string;
  status?: number;
  message?: string;
}

// Définir l'interface pour la réponse d'erreur
interface ErrorResponse {
  error?: string;
  message?: string;
  status?: number;
}

class InvoiceService {
  globalStore: GlobalStore;
  invoices: Invoice[] = [];

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
  }

  // Méthode pour gérer les réponses
  async handleResponse(response) {
    if (response.ok) {
      const data = await response.json();
      this.globalStore.isAuthenticated = true;
      if (data["hydra:member"] === undefined) {
        return { success: true, data };
      }
      if (data) {
        this.invoices = data["hydra:member"];
      }
      return { success: true, data: this.invoices };
    } else {
      const errorData: ErrorResponse = await response.json();
      return {
        success: false,
        error: errorData.error || errorData.message || `Error with status: ${response.status}`,
        status: response.status,
      };
    }
  }

   // Méthode pour générer un numéro de facture
   async generateInvoiceNumber(format, prefix) {
    try {
      const response = await fetch(`${ApiURL.Invoices}/number/generate?format=${format}&prefix=${prefix}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
  
  // Fetch all invoices
  async getInvoices(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch an invoice by its ID
  async getInvoiceById(id: number): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.InvoicesById}${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Post a new invoice
  async postInvoice(
    invoiceData: Record<string, any>
  ): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(invoiceData),
      });
      
      const result = await this.handleResponse(response);
      
      if (!result.success && result.error) {
        // Vérifier si l'erreur concerne un chevauchement de dates
        if (result.error.includes('période est déjà réservée') || 
            result.error.includes('périodes de location se chevauchent')) {
          toast.error(result.error);
        } else {
          toast.error("Une erreur est survenue lors de la création de la facture");
        }
      }
      
      return result;
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      toast.error(error.message || "Une erreur est survenue lors de la création de la facture");
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Update an existing invoice
  async putInvoice(
    id: string,
    invoiceData: Record<string, any>
  ): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.Invoices}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(invoiceData),
      });
      
      const result = await this.handleResponse(response);
      
      if (!result.success && result.error) {
        // Vérifier si l'erreur concerne un chevauchement de dates
        if (result.error.includes('période est déjà réservée') || 
            result.error.includes('périodes de location se chevauchent')) {
          toast.error(result.error);
        } else {
          toast.error("Une erreur est survenue lors de la mise à jour de la facture");
        }
      }
      
      return result;
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      toast.error(error.message || "Une erreur est survenue lors de la mise à jour de la facture");
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
  // Update invoice status
  async updateInvoiceStatus(
    id: number,
    status: string,
    installmentSchedule: Array<string | null>,
    invoiceGenerationSchedule?: any
  ): Promise<InvoiceResponse> {
    try {
      // Construire l'objet de données à envoyer
      const requestData: any = {
        status
      };

      // Ajouter installmentSchedule seulement s'il y a des éléments
      if (installmentSchedule && installmentSchedule.length > 0) {
        requestData.installmentSchedule = installmentSchedule;
      }

      // Ajouter invoiceGenerationSchedule seulement s'il existe
      if (invoiceGenerationSchedule) {
        requestData.invoiceGenerationSchedule = {
          frequency: invoiceGenerationSchedule.frequency,
          nextGenerationDate: invoiceGenerationSchedule.nextGenerationDate,
          amountTtc: invoiceGenerationSchedule.amountTtc,
          isInfinite: invoiceGenerationSchedule.isInfinite,
          startDate: invoiceGenerationSchedule.startDate,
          duration: invoiceGenerationSchedule.duration,
          paidAt: invoiceGenerationSchedule.paidAt,
          remainingOccurrences: invoiceGenerationSchedule.remainingOccurrences
        };
      }

      console.log("Données envoyées à l'API:", requestData);

      const response = await fetch(`${ApiURL.Invoices}/${id}/updatestatus`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch invoices by a specific user ID
  async getInvoicesByUserId(id: string): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.InvoicesByUserId}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch tax rates
  async getTaxeRates(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.taxeRates, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Nouvelle méthode : Marquer une facture comme "Non Payé"
  async markInvoiceAsNonPaid(id: number): Promise<InvoiceResponse> {
    try {
      const response = await fetch(
        `${ApiURL.Invoices}/${id}/mark_as_non_paid`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.globalStore.authService.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Méthode pour récupérer les paiements
  async getPayments(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
  
      // Vérifiez si la réponse est correcte
      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }
  
      // Appelez handleResponse directement sur la réponse
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
}

export default InvoiceService;
