import React from "react";
import { Box, Typography, Card, CardContent, TextField } from "@mui/material";

interface Commercial {
  id: number;
  companyName: string;
  contactEmail: string;
  officeNumber: string;
  address: string;
}

interface Product {
  "@id": string;
  "@type": string;
  id: number;
  ref: string;
  name: string;
  isTemporaryLocation?: boolean;
  AdvertisingLocation: Array<{
    "@id": string;
    "@type": string;
    id: number;
    isTemporary?: boolean;
    historyAdvertisingLocations: Array<{
      "@id": string;
      "@type": string;
      customerId: {
        "@id": string;
        "@type": string;
        companyName: string;
      };
      AdvertisingLocation: string;
      startDate: string;
      endDate: string;
      currentlyActive: boolean;
      customerCompanyName: string;
    }>;
  }>;
  productLocalisation: {
    city: string;
    address: string;
    postalCode: string;
    direction: string;
    latitude: string;
    longitude: string;
  };
}

interface SteperRecapitulatifProps {
  colors: any;
  commercial: Commercial | undefined;
  product: Product | undefined;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export default function SteperRecapitulatif({
  colors,
  commercial,
  product,
  dateRange,
}: SteperRecapitulatifProps) {
  console.log("Commercial data in Recap:", commercial);

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR");
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
        },
        gap: 2,
        width: "100%",
        mb: 3,
      }}
    >
      <Card
        variant="outlined"
        sx={{ mt: 3, backgroundColor: colors.primary[400] }}
      >
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              Informations commercial
            </Typography>
            <Card
              variant="outlined"
              sx={{
                p: 2,
                backgroundColor: colors.primary[400],
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <TextField
                  label="Nom de l'entreprise"
                  value={commercial?.companyName || "Non spécifié"}
                  disabled
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label="Email"
                  value={commercial?.contactEmail || "Non spécifié"}
                  disabled
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label="Téléphone"
                  value={commercial?.officeNumber || "Non spécifié"}
                  disabled
                  fullWidth
                />
                <TextField
                  label="Addresse"
                  value={commercial?.address || "Non spécifié"}
                  disabled
                  fullWidth
                />
              </Box>
            </Card>
          </Box>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ mt: 3, backgroundColor: colors.primary[400] }}
      >
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              Informations emplacement et dates
              {product?.isTemporaryLocation && (
                <Typography
                  component="span"
                  sx={{ 
                    ml: 2,
                    color: colors.greenAccent[500],
                    fontSize: '0.8em'
                  }}
                >
                  (Emplacement temporaire)
                </Typography>
              )}
            </Typography>
            <Card
              variant="outlined"
              sx={{
                p: 2,
                backgroundColor: colors.primary[400],
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="Référence"
                    value={product?.ref || "Non spécifié"}
                    disabled
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                  <TextField
                    label="Nom"
                    value={product?.name || "Non spécifié"}
                    disabled
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                </Box>
                <TextField
                  label="Adresse"
                  value={product?.productLocalisation?.address || "Non spécifié"}
                  disabled
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    label="Ville"
                    value={product?.productLocalisation?.city || "Non spécifié"}
                    disabled
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                  <TextField
                    label="Code postal"
                    value={product?.productLocalisation?.postalCode || "Non spécifié"}
                    disabled
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="Lagitude"
                    value={
                      product?.productLocalisation?.latitude || "Non défini"
                    }
                    disabled
                    fullWidth
                  />
                  <TextField
                    label="Longitude"
                    value={
                      product?.productLocalisation?.longitude || "Non défini"
                    }
                    disabled
                    fullWidth
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="Date de début"
                    value={formatDate(dateRange.startDate)}
                    disabled
                    fullWidth
                  />
                  <TextField
                    label="Date de fin"
                    value={formatDate(dateRange.endDate)}
                    disabled
                    fullWidth
                  />
                </Box>
                <TextField
                  label="Direction"
                  value={
                    product?.productLocalisation?.direction || "Non spécifié"
                  }
                  disabled
                  fullWidth
                  sx={{ mb: 1 }}
                />
              </Box>
            </Card>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
