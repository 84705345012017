import React, { useEffect, useState } from "react";
import { DialogActions, Button, Box, Grid, colors, CircularProgress } from "@mui/material";
import { ThemeModal } from "../../../components/modal/Modal";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import {
  InvoiceHeader,
  InvoiceDetails,
  PaymentDetails,
  StatusSelect,
} from "./index.ts";
import { InvoiceData, InvoiceStatusModalProps } from "./types";

export const InvoiceStatusModal: React.FC<InvoiceStatusModalProps> = ({
  open,
  handleClose,
  selectedInvoice,
  fetchInvoicesCallback,
}) => {
  const store = useGlobalStore();
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
  const [showPaymentSection, setShowPaymentSection] = useState(true);
  const [submitForm, setSubmitForm] = useState(false);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const result = await store.invoice.getInvoiceById(selectedInvoice.id);
        if (result.success) {
          console.log("Données de la facture STATUSMODAL :", result.data);
          setInvoiceData(result.data);

          const hasInstallments = result.data?.payment?.installmentSchedules?.length > 0;
          const isRecurring = result.data?.invoiceGenerationSchedule !== null;
          const paymentSectionVisible =
            result.data?.status !== "DRAFT" &&
            result.data?.status !== "CANCELLED" &&
            
             (hasInstallments || isRecurring)
            
          setShowPaymentSection(paymentSectionVisible);
        } else {
          toast.error(
            "Une erreur est survenue lors de la récupération de la facture."
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la facture :", error);
      }
    };

    if (open && selectedInvoice) {
      fetchInvoice();
    }
  }, [open, selectedInvoice, store.invoice]);

  // Initialisation de `initialValues` avec la structure correcte
  const initialValues = {
    statut: invoiceData ? invoiceData.status : "DRAFT",
    installmentSchedule:
      invoiceData?.payment?.installmentSchedules?.map((schedule) => ({
        id: schedule.id,
        amount_ttc: parseFloat(schedule.amountTtc),
        paidDate: schedule.paidAt ? schedule.paidAt.split("T")[0] : "",
        dueDate: schedule.dueDate,
      })) || [],
    // Correction ici pour inclure toutes les propriétés
    invoiceGenerationSchedule: invoiceData?.invoiceGenerationSchedule ? {
      frequency: invoiceData.invoiceGenerationSchedule.frequency,
      nextGenerationDate: invoiceData.invoiceGenerationSchedule.nextGenerationDate,
      amountTtc: invoiceData.invoiceGenerationSchedule.amountTtc,
      isInfinite: invoiceData.invoiceGenerationSchedule.isInfinite || false, // Notez le 'isInfnite' au lieu de 'isInfinite'
      startDate: invoiceData.invoiceGenerationSchedule.startDate,
      duration: invoiceData.invoiceGenerationSchedule.duration || "0", // Valeur par défaut si undefined
      paidAt: invoiceData.invoiceGenerationSchedule.paidAt || null,
      remainingOccurrences: invoiceData.invoiceGenerationSchedule.remainingOccurrences 
        ? invoiceData.invoiceGenerationSchedule.remainingOccurrences
        : 0,
    } : null
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    console.log("Status envoyé :", values.status)
    console.log("échéancier envoyé", values.installmentSchedule)
    
    // Construction de l'objet à envoyer
    let dataToSend: any = {
      status: values.statut,
      installmentSchedule: []
    };

    // Si c'est une facture récurrente, on ajoute les données de invoiceGenerationSchedule
    if (values.invoiceGenerationSchedule) {
      dataToSend.invoiceGenerationSchedule = {
        frequency: values.invoiceGenerationSchedule.frequency,
        nextGenerationDate: values.invoiceGenerationSchedule.nextGenerationDate,
        amountTtc: values.invoiceGenerationSchedule.amountTtc,
        isInfinite: values.invoiceGenerationSchedule.isInfinite,
        startDate: values.invoiceGenerationSchedule.startDate,
        duration: values.invoiceGenerationSchedule.duration,
        paidAt: values.invoiceGenerationSchedule.paidAt,
        remainingOccurrences: values.invoiceGenerationSchedule.remainingOccurrences
      };
    } else {
      // Pour les factures simples, on traite l'échéancier normal
      dataToSend.installmentSchedule = values.installmentSchedule.map(
        (payment: any) => ({
          id: payment.id,
          amount_ttc: payment.setAmountTtc,
          paidAt: payment.paidDate ? payment.paidDate : null,
          dueDate: payment.dueDate,
        })
      );
    }

    console.log("Données à envoyer :", dataToSend);

    try {
      const result = await store.invoice.updateInvoiceStatus(
        selectedInvoice.id,
        dataToSend.status,
        dataToSend.installmentSchedule,
        dataToSend.invoiceGenerationSchedule // Ajout du paramètre invoiceGenerationSchedule
      );
      if (result.success) {
        toast.success("Le statut de la facture a bien été modifié.");
        fetchInvoicesCallback();
        handleClose();
      } else {
        toast.error("Erreur lors de la modification du statut.");
      }
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    } finally {
      setSubmitting(false);
    }
  };

  // Définir les options de statut en fonction de l'état de la facture
  const getStatusOptions = (currentStatus: string) => {
    switch (currentStatus) {
      case "DRAFT":
        return ["DRAFT", "PUBLISHED", "CANCELLED"];
      case "PUBLISHED":
        return ["PUBLISHED", "CANCELLED"];
      default:
        return [currentStatus];
    }
  };

  // Fonction pour marquer la facture comme "Non Payé"
  const handleNonPaid = async () => {
    try {
      const result = await store.invoice.markInvoiceAsNonPaid(
        selectedInvoice.id
      );
      if (result.success) {
        toast.success(
          "Le statut de la facture a été mis à jour comme Non Payé."
        );
        fetchInvoicesCallback();
        handleClose();
      } else {
        toast.error("Erreur lors de la modification du statut.");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la soumission de l'action Non Payé :",
        error
      );
    }
  };

  // Fonction de traduction des statuts
  const getStatusTranslation = (status: string) => {
    const translations: Record<string, string> = {
      DRAFT: "Brouillon",
      CREATED: "Créée",
      PUBLISHED: "Publiée",
      PAID: "Payée",
      UNPAID: "Non payée",
      LATE: "En retard",
      CANCELLED: "Annulée",
    };

    return translations[status] || status; // Retourne la traduction ou le statut d'origine
  };

  // Variables pour les dimensions dynamiques
  const invoiceDetailsGridSize = showPaymentSection ? 6 : 12;

  // Vérifiez si le formulaire doit être rendu
  if (!invoiceData) {
    return <div><CircularProgress/></div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => {
        // Log pour les valeurs à chaque rendu
        // Fonction pour gérer le clic sur le bouton
        const handleClickSubmit = (status: string) => {
          setFieldValue("statut", status); // Mettez à jour le statut
          handleSubmit(); // Soumettez le formulaire
        };

        return (
          <form id="FormStatus" onSubmit={handleSubmit}>
            <ThemeModal open={open} onClose={handleClose}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  backgroundColor: colors.grey[800],
                  padding: 2,
                  // Définir des dimensions minimales pour la modal
                  minWidth: "1000px", // Largeur minimale en pixels
                }}
              >
                <InvoiceHeader
                  invoiceData={invoiceData}
                  setFieldValue={handleChange}
                  handleClickSubmit={handleClickSubmit} // Passez la fonction ici
                />
                <Grid container>
                  <InvoiceDetails
                    invoiceData={invoiceData}
                    InvoiceDetailsGridSize={invoiceDetailsGridSize}
                    StatusComponent={
                      <StatusSelect
                        currentStatus={invoiceData.status}
                        handleChange={handleChange}
                        value={values.statut}
                        getStatusTranslation={getStatusTranslation}
                        paymentStatus={invoiceData.paymentStatus}
                      />
                    }
                    invoiceGenerationSchedule={invoiceData?.invoiceGenerationSchedule}
                  />
                  {showPaymentSection && (
                    <PaymentDetails
                      Values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  )}
                </Grid>
                <DialogActions>
                  <Button onClick={handleClose}>Fermer</Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickSubmit(values.statut)}
                    disabled={isSubmitting}
                  >
                    Enregistrer
                  </Button>
                </DialogActions>
              </Box>
            </ThemeModal>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvoiceStatusModal;
