import React, { useState, useEffect } from "react";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import { Formik } from "formik";
import { Box } from "@mui/material";
import SteperLocation from "./Steper/SteperLocation.tsx";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { toast } from "react-toastify";

interface User {
  "@id": string;
  "@type": string;
  id: number;
  email: string;
  roles: string[];
  firstName: string;
  lastName: string;
  phone: string;
  createdAt: string;
}

interface Product {
  "@id": string;
  "@type": string;
  id: number;
  ref: string;
  name: string;
  AdvertisingLocation: Array<{
    "@id": string;
    "@type": string;
    id: number;
    HistoryAdvertisingLocations: Array<{
      "@id": string;
      "@type": string;
      currentlyActive: boolean;
      startDate?: string;
      endDate?: string;
      customerCompanyName?: string;
    }>;
  }>;
  productLocalisation: {
    city: string;
    address: string;
    postalCode: string;
    direction: string;
    latitude: string;
    longitude: string;
  };
}

interface LocationCommercial {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface LocationEmplacement {
  id: number;
  ref: string;
  name: string;
  advertisingLocationId?: number;
  city: string;
  AdvertisingLocation?: Array<{
    id: number;
    [key: string]: any;
  }>;
}

interface LocationDateRange {
  startDate: string;
  endDate: string;
}

interface LocationData {
  commercial?: LocationCommercial | null;
  emplacement?: LocationEmplacement | null;
  dateRange?: LocationDateRange;
}

interface EditLocationFormProps {
  title: string;
  subtitle: string;
  onLocationDataUpdate: (data: Partial<LocationData>) => void;
  setDocumentType: (type: string) => void;
  preselectedCommercial: string | null;
}

export default function EditLocationForm({
  title,
  subtitle,
  onLocationDataUpdate,
  setDocumentType,
  preselectedCommercial
}: EditLocationFormProps) {
  const store = useGlobalStore();
  const [users, setUsers] = useState<User[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCommercial, setSelectedCommercial] = useState<string | null>(preselectedCommercial);
  const [selectedEmplacement, setSelectedEmplacement] = useState<number | null>(null);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Charger les utilisateurs
        const usersResult = await store.product.getUsers();
        if (usersResult.success && usersResult.data) {
          const userData = Array.isArray(usersResult.data) ? usersResult.data : [];
          setUsers(userData);
        }

        // Charger les produits
        const productsResult = await store.product.getProducts();
        if (productsResult.success && productsResult.data) {
          const productData = Array.isArray(productsResult.data) ? productsResult.data : [];
          setProducts(productData);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        toast.error("Erreur lors du chargement des données");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [store]);

  const handleCommercialSelect = (commercialId: string) => {
    setSelectedCommercial(commercialId);
    const selectedUser = users.find(user => user.id.toString() === commercialId);
    if (selectedUser) {
      onLocationDataUpdate({
        commercial: selectedUser
      });
    }
  };

  const handleEmplacementSelect = (emplacementId: number) => {
    setSelectedEmplacement(emplacementId);
    const selectedProduct = products.find(p => 
      p.AdvertisingLocation.some(loc => loc.id === emplacementId)
    );
    if (selectedProduct) {
      onLocationDataUpdate({
        emplacement: selectedProduct
      });
    }
  };

  const handleDateChange = (startDate: string, endDate: string) => {
    const newDateRange = { startDate, endDate };
    setDateRange(newDateRange);
    onLocationDataUpdate({
      dateRange: newDateRange
    });
  };

  return (
    <>
      <Box m="20px">
        <Dashboard title={title} subtitle={subtitle} />
      </Box>
      <Box>
        <Formik
          initialValues={{ name: "", description: "" }}
          onSubmit={(values, actions) => {
            console.log(values);
            actions.setSubmitting(false);
          }}
        >
          {() => (
            <Box sx={{ 
              color: "#FFFFF ", 
              maxWidth: "800px", 
              width: "8.27in", 
              height: "11.69in", 
              margin: "20px auto", 
              fontFamily: "Arial, sans-serif", 
              padding: "20px", 
              marginLeft: "calc(50% - 400px - 125px)"
            }}>
              <SteperLocation
                users={users}
                products={products}
                isLoading={isLoading}
                selectedCommercial={selectedCommercial}
                selectedEmplacement={selectedEmplacement}
                dateRange={dateRange}
                onCommercialSelect={handleCommercialSelect}
                onEmplacementSelect={handleEmplacementSelect}
                onDateChange={handleDateChange}
                onLocationDataUpdate={onLocationDataUpdate}
                setDocumentType={setDocumentType}
                preselectedCommercial={preselectedCommercial}
              />
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
}
