import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import StepperEmplacementHistory from "./StepperEmplacementHistory.tsx";
import AddIcon from "@mui/icons-material/Add";

interface Product {
  "@id": string;
  "@type": string;
  id: number;
  ref: string;
  name: string;
  AdvertisingLocation: Array<{
    "@id": string;
    "@type": string;
    id: number;
    isTemporary?: boolean;
    historyAdvertisingLocations: Array<{
      "@id": string;
      "@type": string;
      customerId: {
        "@id": string;
        "@type": string;
        companyName: string;
      };
      AdvertisingLocation: string;
      startDate: string;
      endDate: string;
      currentlyActive: boolean;
      customerCompanyName: string;
    }>;
  }>;
  productLocalisation: {
    city: string;
  };
}

interface StepperEmplacementProps {
  colors: any;
  products: Product[];
  onSelectEmplacement: (emplacementId: number) => void;
  selectedEmplacement: number | null;
  isLoading: boolean;
  onAddTemporaryLocation: (productRef: string) => void;
}

export default function StepperEmplacement({
  colors,
  products,
  onSelectEmplacement,
  selectedEmplacement,
  isLoading,
  onAddTemporaryLocation,
}: StepperEmplacementProps) {
  // Grouper les produits par référence
  const groupedProducts = React.useMemo(() => {
    const groups = new Map<string, Product[]>();

    products.forEach((product) => {
      const existingGroup = groups.get(product.ref);
      if (existingGroup) {
        existingGroup.push(product);
      } else {
        groups.set(product.ref, [product]);
      }
    });

    return Array.from(groups.entries()).map(([ref, products]) => ({
      ref,
      products,
      totalLocations: products.reduce(
        (sum, p) => sum + p.AdvertisingLocation.length,
        0
      ),
    }));
  }, [products]);

  // Formater les emplacements pour le select
  const allLocations = React.useMemo(() => {
    return products.flatMap((product) =>
      product.AdvertisingLocation.map((location, index) => ({
        id: location.id,
        name: product.name,
        ref: product.ref,
        city: product.productLocalisation?.city || "",
        locationNumber: index + 1,
        totalLocations: product.AdvertisingLocation.length,
      }))
    );
  }, [products]);

  const handleAddTemporaryLocation = React.useCallback((ref: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Appeler directement la fonction parent
    onAddTemporaryLocation(ref);
    
    // Sélectionner automatiquement le nouvel emplacement après un court délai
    setTimeout(() => {
      const product = products.find(p => p.ref === ref);
      if (product) {
        const lastLocation = product.AdvertisingLocation[product.AdvertisingLocation.length - 1];
        if (lastLocation && lastLocation.isTemporary) {
          onSelectEmplacement(lastLocation.id);
        }
      }
    }, 0);
  }, [onAddTemporaryLocation, products, onSelectEmplacement]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {groupedProducts.map(({ ref, products, totalLocations }) => (
              <Card
                key={ref}
                variant="outlined"
                sx={{ backgroundColor: colors.primary[400] }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px" }}>
                    <Typography variant="h6" gutterBottom>
                      Référence: {ref}
                    </Typography>
                    <Button 
                      variant="outlined" 
                      color="secondary" 
                      sx={{ fontSize: "12px", width:"200px", height:"50px" }}
                      onClick={(e) => handleAddTemporaryLocation(ref, e)}
                    >
                      <AddIcon />
                      Ajouter un emplacement
                    </Button>
                  </Box>
                  <StepperEmplacementHistory
                    colors={colors}
                    products={products}
                  />
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              backgroundColor: colors.primary[400],
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: colors.grey[100], fontSize: "12px" }}
            >
              Sélectionner l'emplacement
            </Typography>
            <Select
              fullWidth
              value={selectedEmplacement || ""}
              onChange={(e) => onSelectEmplacement(Number(e.target.value))}
              variant="outlined"
            >
              <MenuItem value="">Sélectionnez un emplacement</MenuItem>
              {allLocations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {`${location.name}${
                    location.totalLocations > 1
                      ? ` ${location.locationNumber}`
                      : ""
                  } - ${location.city} (${location.ref})`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
