import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  useTheme,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import { mockTransactions } from "../../store/data/mockdata";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Header from "../../components/header/Header";
import StatBox from "../../components/statBox/StatBox";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PaymentIcon from "@mui/icons-material/Payment";
import TrafficIcon from "@mui/icons-material/Traffic";
import { useGlobalStore } from "../../store/globalStore.tsx";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import LineChart from "../../components/linceChart/LinceChart";

const IndexPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customersPerMonth, setCustomersPerMonth] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const store = useGlobalStore();
  const [productsData, setProductsData] = useState([]);
  const [advertisingLocations, setAdvertisingLocations] = useState([]);

  useEffect(() => {
    const fetchCustomersPerMonth = async () => {
      const response = await store.customer.getCustomers();
      if (response.success) {
        const customersData = Array.isArray(response.data)
          ? response.data.map((customer) => ({
              id: customer.id,
              createdAt: customer.createdAt,
              companyName: customer.companyName,
            }))
          : [];
        setCustomersPerMonth(customersData);
        console.log("Clients récupérés :", customersData);
        console.log("Nombre de clients récupérés :", customersData.length);
        return customersData.length;
      } else {
        console.error("Erreur lors de la récupération des clients :", response.error);
        return 0;
      }
    };

    const fetchPayments = async () => {
      const response = await store.invoice.getPayments();
      if (response.success) {
        const paymentsData = response.data.map((payment) => {
          const installments = payment?.payment?.installmentSchedules || [];

          return {
            id: payment.id || "ID non disponible",
            paymentStatus: payment.paymentStatus || "Status non disponible",
            amountTotal: payment.totalWithTax || "Montant non disponible",
            companyName: payment.customer?.companyName || "Nom non disponible",
            dueDate:
              installments.length > 0
                ? new Date(installments[0]?.dueDate)
                : null,
            paidAt: installments
              .map((schedule) =>
                schedule.paidAt ? new Date(schedule.paidAt) : null
              )
              .filter((date) => date !== null),
            issuedAt: payment.issuedAt ? new Date(payment.issuedAt) : null,
          };
        });
        setPayments(paymentsData);
        console.log("Paiements récupérés:", paymentsData);
      } else {
        console.error(
          "Erreur lors de la récupération des paiements :",
          response.error
        );
      }
    };

    const fetchProducts = async () => {
      const response = await store.product.getProducts();
      if (response.success) {
        const remappedProductsData = response.data.map((product) => ({
          id: product.id || "ID non disponible",
          createdAt: product.createdAt || "Date non disponible",
        }));
        setProductsData(remappedProductsData);
        console.log("Produits récupérés :", remappedProductsData);
      } else {
        console.error(
          "Erreur lors de la récupération des produits :",
          response.error
        );
      }
    };

    const fetchAdvertisingLocations = async () => {
      const response = await store.product.getProducts();
      console.log("Réponse de l'API:", response); // Debug
      if (response.success) {
        setAdvertisingLocations(response.data);
        console.log("Données stockées:", response.data); // Debug
      } else {
        console.error(
          "Erreur lors de la récupération des emplacements:",
          response.error
        );
      }
    };

    const loadData = async () => {
      await fetchCustomersPerMonth();
      await fetchPayments();
      await fetchProducts();
      await fetchAdvertisingLocations();
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);
  console.log("Date actuelle :", now);
  console.log("Date d'il y a 30 jours :", thirtyDaysAgo);

  const latePaymentsMoreThan30Days = payments.filter((payment) => {
    if (payment.paymentStatus === "LATE") {
      const dueDate = new Date(payment.dueDate);

      console.log(
        `Paiement ID: ${payment.id}, Statut: ${payment.paymentStatus}`
      );
      console.log("Date d'échéance :", dueDate);

      const isLateMoreThan30Days = dueDate < thirtyDaysAgo;
      console.log(
        `Est en retard de plus de 30 jours ? ${isLateMoreThan30Days}`
      );

      return isLateMoreThan30Days; // Paiements en retard de plus de 30 jours
    }
    return false;
  });

  const latePaymentsLessThan30Days = payments.filter((payment) => {
    if (payment.paymentStatus === "LATE") {
      const dueDate = new Date(payment.dueDate);
      const isLateLessThan30Days = dueDate >= thirtyDaysAgo && dueDate < now;
      console.log(
        `Est en retard de moins de 30 jours ? ${isLateLessThan30Days}`
      );
      return isLateLessThan30Days; // Paiements en retard de moins de 30 jours
    }
    return false;
  });

  const getPaymentsThisMonthCount = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const paymentsThisMonth = payments.reduce((count, payment) => {
      if (payment.paymentStatus === "PAID") {
        const paymentCount = payment.paidAt.reduce(
          (innerCount, paymentDate) => {
            const date = new Date(paymentDate);
            return (
              innerCount + (date >= startOfMonth && date <= endOfMonth ? 1 : 0)
            );
          },
          0
        );

        if (paymentCount > 0) {
          console.log(
            `Client : ${payment.companyName}, Montant : ${(
              payment.amountTotal / 100
            ).toFixed(2)} €, Dates : ${payment.paidAt
              .map((date) => new Date(date).toLocaleDateString("fr-FR"))
              .join(", ")}`
          );
        }

        return count + paymentCount;
      }
      return count;
    }, 0);

    return paymentsThisMonth;
  };

  const getAdvertisingLocationsThisMonthCount = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    console.log("Données reçues:", advertisingLocations);

    // Parcourir directement les produits
    const count = advertisingLocations.reduce((total, product) => {
      console.log("Traitement du produit:", product.ref);

      const advertisingLocations = product.AdvertisingLocation || [];
      return (
        total +
        advertisingLocations.reduce((locationTotal, location) => {
          console.log("Traitement de l'emplacement:", location.id);

          const histories = location.historyAdvertisingLocations || [];
          return (
            locationTotal +
            histories.reduce((historyTotal, history) => {
              const createdAt = new Date(history.createdAt);
              const isInCurrentMonth =
                createdAt >= startOfMonth && createdAt <= endOfMonth;

              console.log(
                `Emplacement ID: ${location.id}, Date de création: ${createdAt}, Dans le mois courant: ${isInCurrentMonth}`
              );

              return historyTotal + (isInCurrentMonth ? 1 : 0);
            }, 0)
          );
        }, 0)
      );
    }, 0);

    console.log("Nombre total d'emplacements ce mois:", count);
    return count;
  };

  const getInvoicesThisMonthCount = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const invoicesThisMonth = payments
      .filter((payment) => payment.issuedAt)
      .filter((payment) => {
        const issuedAtDate = new Date(payment.issuedAt);
        return issuedAtDate >= startOfMonth && issuedAtDate <= endOfMonth;
      });

    return invoicesThisMonth.length;
  };

  const getCustomersThisMonthCount = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    console.log("Période de filtrage :", {
      debut: startOfMonth.toLocaleDateString(),
      fin: endOfMonth.toLocaleDateString()
    });

    const customersThisMonth = customersPerMonth.filter((customer) => {
      const createdAt = new Date(customer.createdAt);
      const isInCurrentMonth = createdAt >= startOfMonth && createdAt <= endOfMonth;
      
      console.log(`Client ${customer.companyName}:`, {
        dateCreation: createdAt.toLocaleDateString(),
        estDansMoisCourant: isInCurrentMonth
      });
      
      return isInCurrentMonth;
    });

    console.log("Clients de ce mois :", customersThisMonth);
    return customersThisMonth.length;
  };

  const getRevenueThisMonth = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Log des paiements avant le filtrage
    console.log("Tous les paiements :", payments);

    const revenueThisMonth = payments.filter((payment) => {
      const paidDates = payment.paidAt.map((date) => new Date(date));
      return (
        paidDates.some(
          (paidDate) => paidDate >= startOfMonth && paidDate <= endOfMonth
        ) && payment.paymentStatus === "PAID"
      );
    });

    // Log des paiements filtrés
    console.log("Paiements filtrés pour ce mois :", revenueThisMonth);

    // Calculer le revenu total en additionnant les montants de tous les paiements
    const totalRevenue = revenueThisMonth.reduce((sum, payment) => {
      const amount = parseFloat(payment.amountTotal) / 100;
      console.log(`Montant pour le paiement ID ${payment.id}: ${amount} €`);
      return sum + amount;
    }, 0);

    return totalRevenue.toFixed(2) + " €";
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="DASHBOARD"
          subtitle="Les chiffres clés de votre entreprise"
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={getAdvertisingLocationsThisMonthCount().toString() || "0"}
            subtitle="Nombre de locations vendues"
            progress="0.75"
            increase="+14%"
            icon={
              <OtherHousesIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={getPaymentsThisMonthCount() || 0}
            subtitle="Paiements du mois"
            progress="0.50"
            increase="+21%"
            icon={
              <PaymentIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={getCustomersThisMonthCount().toString() || 0}
            subtitle="Nouveaux clients ce mois-ci"
            progress={Math.min(customersPerMonth.length / 100, 1).toFixed(2)}
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={getInvoicesThisMonthCount().toString() || 0}
            subtitle="Nombre de factures du mois"
            progress="0.80"
            increase="+43%"
            icon={
              <ReceiptIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2: Revenu */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          {/* Section Revenu complète, avec graphique et bouton de téléchargement */}
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenu
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {getRevenueThisMonth().toString() || 0}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>

        {/* Tabs Section for Paiement Récent */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          {/* Tabs */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Derniers paiements" />
            <Tab label="Retard < 30 jours" sx={{ fontSize: "12px" }} />
            <Tab label="Retard > 30 jours" />
          </Tabs>

          {selectedTab === 0 ? ( // Derniers paiements
            <>
              <Box p="15px">
                <Typography variant="h6" color={colors.grey[100]} mb="10px">
                  Derniers Paiements :
                </Typography>
                {payments.filter((payment) => payment.paymentStatus === "PAID")
                  .length > 0 ? (
                  payments
                    .filter((payment) => payment.paymentStatus === "PAID")
                    .map((payment) => {
                      // Filtrer les dates de paiement pour le mois en cours
                      const now = new Date();
                      const startOfMonth = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        1
                      );
                      const endOfMonth = new Date(
                        now.getFullYear(),
                        now.getMonth() + 1,
                        0
                      );

                      const paymentsThisMonth = payment.paidAt.filter(
                        (paymentDate) => {
                          const date = new Date(paymentDate);
                          return date >= startOfMonth && date <= endOfMonth;
                        }
                      );

                      // Vérifier si des paiements ont été reçus ce mois-ci
                      if (paymentsThisMonth.length > 0) {
                        return (
                          <Box
                            key={payment.id}
                            display="flex"
                            justifyContent="space-between"
                            p="10px"
                            bgcolor={colors.primary[500]}
                            borderRadius="4px"
                            mb="5px"
                          >
                            <Typography color={colors.grey[100]}>
                              Client : {payment.companyName}
                            </Typography>
                            <Typography color={colors.greenAccent[500]}>
                              Montant : {(payment.amountTotal / 100).toFixed(2)}{" "}
                              €<br />
                              {/* Affichage des dates de paiement dans des cases séparées */}
                              {paymentsThisMonth.map((date, index) => (
                                <Box
                                  key={index}
                                  bgcolor={colors.primary[500]}
                                  borderRadius="4px"
                                  p="5px"
                                  mb="5px"
                                >
                                  {new Date(date).toLocaleDateString("fr-FR")}
                                </Box>
                              ))}
                            </Typography>
                          </Box>
                        );
                      }
                      return null;
                    })
                    .filter(Boolean) // Filtrer les éléments null
                ) : (
                  <Typography p="15px" color={colors.grey[100]}>
                    Aucun paiement payé pour l'instant.
                  </Typography>
                )}
              </Box>
            </>
          ) : selectedTab === 1 ? ( // Retard < 30 jours
            <>
              {latePaymentsLessThan30Days.length > 0 ? (
                <Box p="15px">
                  <Typography variant="h6" color={colors.grey[100]} mb="10px">
                    Factures en retard depuis moins de 30 jours :
                  </Typography>
                  {latePaymentsLessThan30Days.map((payment) => (
                    <Box
                      key={payment.id}
                      display="flex"
                      justifyContent="space-between"
                      p="10px"
                      bgcolor={colors.primary[500]}
                      borderRadius="4px"
                      mb="5px"
                    >
                      <Typography color={colors.grey[100]}>
                        Client : {payment.companyName}
                      </Typography>
                      <Typography color={colors.greenAccent[500]}>
                        Date :{" "}
                        {payment.dueDate
                          ? formatDate(payment.dueDate)
                          : "Date non disponible"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography p="15px" color={colors.grey[100]}>
                  Aucun paiement en retard de moins de 30 jours.
                </Typography>
              )}
            </>
          ) : (
            // Retard > 30 jours
            <>
              {latePaymentsMoreThan30Days.length > 0 ? (
                <Box p="15px">
                  <Typography variant="h6" color={colors.grey[100]} mb="10px">
                    Factures en retard depuis plus de 30 jours :
                  </Typography>
                  {latePaymentsMoreThan30Days.map((payment) => (
                    <Box
                      key={payment.id}
                      display="flex"
                      justifyContent="space-between"
                      p="10px"
                      bgcolor={colors.primary[500]}
                      borderRadius="4px"
                      mb="5px"
                    >
                      <Typography color={colors.grey[100]}>
                        Client : {payment.companyName}
                      </Typography>
                      <Typography color={colors.greenAccent[500]}>
                        Date :{" "}
                        {payment.dueDate
                          ? formatDate(payment.dueDate)
                          : "Date non disponible"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography p="15px" color={colors.grey[100]}>
                  Aucun paiement en retard de plus de 30 jours.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IndexPage;
