import React from "react";
import { Box, Typography, Grid, TextField } from "@mui/material";

interface SteperDateProps {
  onDateChange: (startDate: string, endDate: string) => void;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export default function SteperDate({ onDateChange, dateRange }: SteperDateProps) {
  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <Typography variant="h6" gutterBottom>
        Dates de la Location
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            label="Date de début"
            type="date"
            value={dateRange.startDate}
            onChange={(e) => onDateChange(e.target.value, dateRange.endDate)}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Date de fin"
            type="date"
            value={dateRange.endDate}
            onChange={(e) => onDateChange(dateRange.startDate, e.target.value)}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
