export const InvoiceFrequencyEnum = {
  WEEKLY: 'semaine',
  MONTHLY: 'mois',
  BIMONTHLY: 'bimensuel',
  QUARTERLY: 'trimestriel',
  BIANNUAL: 'semestre',
  ANNUAL: 'annuel',
  BIENNIAL: 'bi-anuel',

  // Getter pour obtenir la valeur backend à partir de l'affichage frontend
  fromDisplay: (display: string): string | null => {
    const mapping = {
      'Hebdomadaire': 'semaine',
      'Mensuelle': 'mois',
      'Bimestrielle': 'bimensuel',
      'Trimestrielle': 'trimestriel',
      'Semestrielle': 'semestre',
      'Annuelle': 'annuel',
      'Bi-annuelle': 'bi-anuel'
    };
    return mapping[display] || null;
  },

  // Getter pour obtenir l'affichage frontend à partir de la valeur backend
  toDisplay: (value: string): string | null => {
    const mapping = {
      'semaine': 'Hebdomadaire',
      'mois': 'Mensuelle',
      'bimensuel': 'Bimestrielle',
      'trimestriel': 'Trimestrielle',
      'semestre': 'Semestrielle',
      'annuel': 'Annuelle',
      'bi-anuel': 'Bi-annuelle'
    };
    return mapping[value] || null;
  }
} as const; 