import React from "react";
import { Grid, Paper, Typography, Divider, Box } from "@mui/material";
import { InvoiceData, InvoiceGenerationSchedule } from "./types";

interface InvoiceDetailsProps {
  invoiceData: InvoiceData;
  InvoiceDetailsGridSize: number;
  StatusComponent: React.ReactNode;
  invoiceGenerationSchedule?: InvoiceGenerationSchedule;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoiceData,
  InvoiceDetailsGridSize,
  StatusComponent,
  invoiceGenerationSchedule,
}) => {
  // Fonction pour déterminer la date d'échéance à afficher
  const getDueDate = () => {    
    if (invoiceGenerationSchedule && invoiceGenerationSchedule.nextGenerationDate) {
      const date = new Date(invoiceGenerationSchedule.nextGenerationDate);
      return date.toLocaleDateString();
    } else if (invoiceData.formatedDueDate) {
      return invoiceData.formatedDueDate;
    }
    return "Non spécifiée";
  };

  return (
    <Grid item xs={InvoiceDetailsGridSize} sx={{ padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Détails de la facture
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="subtitle1">
          <strong>Numéro de facture:</strong> {invoiceData.number}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Créé le:</strong> {invoiceData.formatedIssuedAt}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Échéance:</strong> {getDueDate()}
        </Typography>
        <Box mt={4}>
          <Typography variant="subtitle1">Modifier le statut</Typography>
          {StatusComponent}
        </Box>
      </Paper>
    </Grid>
  );
};

export default InvoiceDetails;
