import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Header from "../../components/header/Header";
import { tokens } from "../../theme";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toJS } from "mobx";

const Calendar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const store = useGlobalStore();

  // Fonction pour récupérer les paiements
  const fetchPayments = async () => {
    const response = await store.invoice.getPayments();
    if (response.success) {
      const filteredPayments = toJS(response.data).filter((payment) =>
        ["PAID", "LATE"].includes(payment.paymentStatus)
      );

      const allEvents = filteredPayments.flatMap((payment) => {
        if (payment.paymentStatus === "PAID") {
          // Récupérer tous les paidAt pour les paiements "PAID"
          return payment.payment.installmentSchedules
            .filter(schedule => schedule.paidAt) // S'assurer qu'il y a un paidAt
            .map((schedule) => {
              return {
                id: `${payment.id}-${schedule.id}`, // Utiliser une combinaison d'ID pour garantir l'unicité
                title: `Paiement reçu: ${(payment.totalWithTax / 100).toFixed(2)} €`,
                date: new Date(schedule.paidAt), // Utiliser la date de paiement
                extendedProps: {
                  customerId: payment.customer.id,
                },
                backgroundColor: "green",
              };
            });
        } else if (payment.paymentStatus === "LATE") {
          // Récupérer les échéances passées pour les paiements en retard
          return payment.payment.installmentSchedules
            .filter((schedule) => new Date(schedule.dueDate) < new Date()) // Filtrer les dueDate passées
            .map((schedule) => {
              return {
                id: payment.id, // Utiliser l'ID du paiement
                title: `Retard de paiement: ${(payment.totalWithTax / 100).toFixed(2)} €`,
                date: new Date(schedule.dueDate), // Utiliser la date d'échéance
                extendedProps: {
                  customerId: payment.customer.id,
                },
                backgroundColor: "orange",
              };
            });
        }
        return []; // Retourner un tableau vide si aucun événement n'est créé
      });

      console.log(JSON.stringify(allEvents, null, 2));
      setCurrentEvents(allEvents);
    } else {
      console.error("Erreur lors de la récupération des paiements:", response.error);
    }
  };

  // Charger les paiements lors de l'initialisation
  useEffect(() => {
    fetchPayments();
  }, []);

  // Fonction pour regrouper et filtrer les événements par mois
  const filteredGroupedEvents = () => {
    const groupEventsByMonth = (events) => {
      return events.reduce((acc, event) => {
        const eventDate = new Date(event.date);
        // Décalage de la date d'un mois en arrière
        eventDate.setMonth(eventDate.getMonth() - 1);
        const month = eventDate.getMonth();
        const year = eventDate.getFullYear();
        const monthYearKey = `${year}-${month}`;
    
        if (!acc[monthYearKey]) {
          acc[monthYearKey] = [];
        }
        acc[monthYearKey].push(event);
        return acc;
      }, {});
    };
  
    const groupedPaidEvents = groupEventsByMonth(
      currentEvents.filter((event) => event.title.startsWith("Paiement reçu"))
    );
    const groupedLateEvents = groupEventsByMonth(
      currentEvents.filter((event) => event.title.startsWith("Retard de paiement"))
    );
  
    const filterBySelectedMonth = (groupedEvents) => {
      const selectedMonthKey = `${selectedMonth.getFullYear()}-${selectedMonth.getMonth()}`;
      return Object.entries(groupedEvents).filter(([key]) => key === selectedMonthKey);
    };
  
    return {
      paid: filterBySelectedMonth(groupedPaidEvents),
      late: filterBySelectedMonth(groupedLateEvents),
    };
  };

  // Mettre à jour le mois sélectionné
  const handleMonthChange = (info) => {
    setSelectedMonth(new Date(info.start));
  };

  // Gérer le clic sur un événement du calendrier
  const handleEventClick = (info) => {
    const customerId = info.event.extendedProps.customerId;
    if (customerId) {
      navigate(`/customer/edit/${customerId}`);
    }
  };

  // Récupération des événements filtrés et groupés
  const { paid: filteredGroupedPaidEvents, late: filteredGroupedLateEvents } = filteredGroupedEvents();

  return (
    <Box m="20px">
      <Header title="Calendrier" subtitle="Un seul calendrier pour voir plus facilement tous vos besoins" />
      <Box display="flex" justifyContent="space-between">
        
        {/* Sidebar */}
        <Box flex="1 1 20%" backgroundColor={colors.primary[400]} p="15px" borderRadius="4px">
          <Typography variant="h5">Events</Typography>

          {/* Accordéon pour les paiements "PAID" */}
          <Accordion>
            <AccordionSummary 
            sx={{backgroundColor: 'green', mt:5}}
            expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Paiements Reçus</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {filteredGroupedPaidEvents.length > 0 ? (
                  filteredGroupedPaidEvents.map(([monthYearKey, events]) => (
                    <div key={monthYearKey}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginTop: 2 }}>
                        {new Date(parseInt(monthYearKey.split('-')[0]), parseInt(monthYearKey.split('-')[1]) + 1).toLocaleString("default", { month: "long", year: "numeric" })}
                      </Typography>
                      {events.map((event) => (
                        <ListItem
                          key={event.id}
                          sx={{
                            backgroundColor: event.backgroundColor,
                            margin: "10px 0",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEventClick({ event })}
                        >
                          <ListItemText primary={event.title} />
                        </ListItem>
                      ))}
                    </div>
                  ))
                ) : (
                  <Typography>Aucun événement pour ce mois.</Typography>
                )}
              </List>
            </AccordionDetails>
          </Accordion>

          {/* Accordéon pour les paiements "LATE" */}
          <Accordion>
            <AccordionSummary 
            sx={{backgroundColor: 'orange', mt: 3}}
            expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Retards de Paiement</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {filteredGroupedLateEvents.length > 0 ? (
                  filteredGroupedLateEvents.map(([monthYearKey, events]) => (
                    <div key={monthYearKey}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginTop: 2 }}>
                        {new Date(parseInt(monthYearKey.split('-')[0]), parseInt(monthYearKey.split('-')[1])).toLocaleString("default", { month: "long", year: "numeric" })}
                      </Typography>
                      {events.map((event) => (
                        <ListItem
                          key={event.id}
                          sx={{
                            backgroundColor: event.backgroundColor,
                            margin: "10px 0",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEventClick({ event })}
                        >
                          <ListItemText primary={event.title} />
                        </ListItem>
                      ))}
                    </div>
                  ))
                ) : (
                  <Typography>Aucun événement pour ce mois.</Typography>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* FullCalendar */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            locale={frLocale}
            initialView="dayGridMonth"
            events={currentEvents}
            datesSet={handleMonthChange}
            eventClick={handleEventClick}
            dayMaxEvents={3}
            dayMaxEventRows={1}
            dayCellContent={(dayInfo) => {
              return (
                <div style={{ color: "white", textAlign: "center" }}>
                  {dayInfo.dayNumberText}
                </div>
              );
            }}
            eventContent={(eventInfo) => {
              return (
                <div style={{ 
                  backgroundColor: eventInfo.event.backgroundColor, 
                  color: "black", 
                  padding: '5px', 
                  borderRadius: '5px',
                  whiteSpace: 'normal', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                }}>
                  {eventInfo.event.title}
                </div>
              );
            }}
            eventDidMount={() => {
              const header = document.querySelector('.fc-popover-header .fc-popover-title');
              if (header) {
                header.style.color = 'black';
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
