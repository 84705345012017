import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { toJS } from "mobx";
import { useEffect, useState } from "react";


const convertToDate = (dateString) => {
  const parts = dateString.split('/');
  if (parts.length === 3) {
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  }
  return null; 
};

const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const store = useGlobalStore();
  const [currentEvents, setCurrentEvents] = useState([]);

  const fetchPayments = async () => {
    const response = await store.invoice.getPayments();
    if (response.success) {
      const filteredPayments = toJS(response.data).filter((payment) =>
        ["PAID", "LATE", "UNPAID"].includes(payment.paymentStatus)
      );

      console.log("Filtered Payments:", filteredPayments);

      const allEvents = filteredPayments.flatMap((payment) => {
        if (payment.paymentStatus === "PAID") {
          // Récupérer tous les paidAt pour les paiements "PAID"
          return payment.payment.installmentSchedules
            .filter(schedule => schedule.paidAt) // S'assurer qu'il y a un paidAt
            .map((schedule) => {
              return {
                id: `${payment.id}-${schedule.id}`, // Utiliser une combinaison d'ID pour garantir l'unicité
                totalWithTax: `${(payment.totalWithTax / 100).toFixed(2)}€`,
                title: `Paiement reçu: ${(payment.totalWithTax / 100).toFixed(2)} €`,
                date: new Date(schedule.paidAt), // Utiliser la date de paiement
                formatedDueDate: `${payment.formatedDueDate}` || "Pas de dates d'échéance",
                extendedProps: {
                  customerId: payment.customer.id,
                },
                backgroundColor: "green",
              };
            });
        } else if (payment.paymentStatus === "LATE") {
          // Récupérer les échéances passées pour les paiements en retard
          return payment.payment.installmentSchedules
            .filter((schedule) => new Date(schedule.dueDate) < new Date() && !schedule.paidAt) // Filtrer les dueDate passées et sans paidAt
            .map((schedule) => {
              return {
                id: payment.id, // Utiliser l'ID du paiement
                totalWithTax: `${(payment.totalWithTax / 100).toFixed(2)}€`,
                title: `Retard de paiement: ${(payment.totalWithTax / 100).toFixed(2)} €`,
      
                date: new Date(schedule.dueDate), // Utiliser la date d'échéance
                extendedProps: {
                  customerId: payment.customer.id,
                },
                backgroundColor: "orange",
              };
            });
        } else if (payment.paymentStatus === "UNPAID") {
          console.log("Unpaid Payment Found:", payment);
          // Vérifier si installmentSchedules est vide
          if (!payment.payment.installmentSchedules || payment.payment.installmentSchedules.length === 0) {
            // Vérifiez si formatedDueDate est défini
            if (payment.formatedDueDate) {
              const dueDate = convertToDate(payment.formatedDueDate); // Convertir la date
              if (dueDate) { // Vérifiez si la conversion a réussi
                return [{
                  id: payment.id,
                  title: `Non payé: ${(parseFloat(payment.totalWithTax) / 100).toFixed(2)} €`,
                  totalWithTax: `${(parseFloat(payment.totalWithTax) / 100).toFixed(2)}€`,
                  date: dueDate, // Utiliser la date convertie
                  formatedDueDate: payment.formatedDueDate || "Pas de dates d'échéance",
                  extendedProps: {
                    customerId: payment.customer.id,
                  },
                  backgroundColor: "red",
                }];
              }
            }
          } else {
            // Si des échéances existent, récupérer la première date d'échéance
            const dueDate = convertToDate(payment.formatedDueDate); // Convertir la date
            if (dueDate) { // Vérifiez si la conversion a réussi
              return [{
                id: payment.id,
                title: `Non payé: ${(parseFloat(payment.totalWithTax) / 100).toFixed(2)} €`,
                totalWithTax: `${(parseFloat(payment.totalWithTax) / 100).toFixed(2)}€`,
                date: dueDate, // Utiliser la date convertie
                formatedDueDate: payment.formatedDueDate || "Pas de dates d'échéance",
                extendedProps: {
                  customerId: payment.customer.id,
                },
                backgroundColor: "red",
              }];
            }
          }
        }
        return []; // Retourner un tableau vide si aucun événement n'est créé
      });

      console.log("All Events:", allEvents);

      // Regrouper les paiements par mois et par statut
      const monthlyData = Array.from({ length: 12 }, (_, i) => ({
        month: new Date(0, i).toLocaleString('default', { month: 'long' }),
        paid: 0,
        late: 0,
        unpaid: 0,
      }));

      allEvents.forEach(event => {
        // Vérifiez si la date est définie avant d'appeler getMonth
        if (event.date) {
          const monthIndex = event.date.getMonth();
          const total = parseFloat(event.totalWithTax); // Assurez-vous que c'est un nombre

          // Vérifiez que monthIndex est valide et que monthlyData[monthIndex] est défini
          if (!isNaN(total) && monthlyData[monthIndex]) { 
            if (event.backgroundColor === "green") {
              monthlyData[monthIndex].paid += total;
            } else if (event.backgroundColor === "orange") {
              monthlyData[monthIndex].late += total;
            } else if (event.backgroundColor === "red") {
              monthlyData[monthIndex].unpaid += total;
            }
          } else {
            console.warn("Total non valide pour l'événement ou index de mois invalide:", event); // Avertir si total est NaN ou index invalide
          }
        } else {
          console.warn("Date non définie pour l'événement:", event); // Avertir si la date est null
        }
      });

      // Ajoutez un console.log pour vérifier le contenu de monthlyData
      console.log("Monthly Data:", monthlyData);

      const dataForChart = [
        {
          id: "Payé",
          color: colors.greenAccent[500],
          data: monthlyData.map((data) => ({ x: data.month, y: Math.max(data.paid, 0) })),
        },
        {
          id: "En Retard",
          color: colors.redAccent[200],
          data: monthlyData.map((data) => ({ x: data.month, y: Math.max(data.late, 0) })),
        },
        {
          id: "Non Payé",
          color: colors.blueAccent[300],
          data: monthlyData.map((data) => ({ x: data.month, y: Math.max(data.unpaid, 0) })),
        },
      ];

      console.table(dataForChart); // Afficher les données pour le graphique
      setCurrentEvents(dataForChart);
    } else {
      console.error("Erreur lors de la récupération des paiements:", response.error);
    }
  };

  // Charger les paiements lors de l'initialisation
  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <ResponsiveLine
      data={currentEvents || []}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} 
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "transportation",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "count",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;