import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import logo_cevep from "../../../img/logo/logo_cevep.webp";
import "./previewInvoice.css";
import { TVA_RATES } from "../../../constants/TvaRate.ts";

export function CreateViewInvoice(invoiceDataObject) {
  console.log(invoiceDataObject, "invoiceDataObject");
  const invoiceData = invoiceDataObject.invoiceData;

  function formatNumber(value) {
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function formatDate(date) {
    return new Date(date).toLocaleDateString("fr-FR");
  }

  if (!invoiceData) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const taxRates = {};
  const totalAmount = invoiceData.totalWithTax / 100;

  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const tvaRate =
        TVA_RATES.find((rate) => rate.id === line.taxRate)?.rate || 0;
      const amountHT = (line.price / 100) * line.quantity;
      const amountTVA = amountHT * (tvaRate / 100);

      if (!taxRates[tvaRate]) {
        taxRates[tvaRate] = {
          value: tvaRate,
          totalAmountTVA: 0,
        };
      }
      taxRates[tvaRate].totalAmountTVA += amountTVA;
    });
  }

  const hasPaymentData = invoiceData.payment ? true : false;
  const downPayment = hasPaymentData
    ? (invoiceData.payment.DepositAmount || 0) / 100
    : 0;
  const remainingAmount = totalAmount - downPayment;
  const totalInstallments = hasPaymentData
    ? invoiceData.payment.installmentSchedules?.length || 0
    : 0;

  return (
    <>
      {invoiceData && (
        <Box className="preview-invoice">
          <Box display="flex" flexDirection="column">
            <Box
              width={"100%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={logo_cevep} alt="" style={{ width: "300px" }} />
            </Box>

            <Box marginTop="50px" display="flex" flexDirection="row">
              <Box
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography fontWeight={700}>16 F rue des Jardins</Typography>
                <Typography fontWeight={700}>95480 PIERRELAYE</Typography>
                <Typography fontWeight={700}>Tél. : 01.34.32.53.53</Typography>
                <Typography fontSize={8} color="#33b540" fontWeight={700}>
                  E. Mail. : pubccvep@gmail.com
                </Typography>
                <Typography fontWeight={700} marginTop="20px" fontSize={20}>
                  Facture {invoiceData.number}
                </Typography>
                <Typography marginBottom="20px">
                  Pierrelaye, le{" "}
                  {new Date(invoiceData.issuedAt).toLocaleDateString("fr-FR")}
                </Typography>
              </Box>

              <Box
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  fontWeight: 700,
                }}
              >
                {invoiceData.customer.companyName && (
                  <Typography>
                    Client: {invoiceData.customer.companyName}
                  </Typography>
                )}
                {invoiceData.customer.numberSiret && (
                  <Typography>
                    N°SIRET: {invoiceData.customer.numberSiret}
                  </Typography>
                )}
                {invoiceData.customer.numberTva && (
                  <Typography>
                    N°TVA: {invoiceData.customer.numberTva}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ width: "100%" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {invoiceData.invoiceLines.some(line => line.historyAdvertisingLocation) && (
                        <>
                          <TableCell>Emplacement</TableCell>
                          <TableCell>Date de début</TableCell>
                          <TableCell>Date de fin</TableCell>
                        </>
                      )}
                      <TableCell>DESIGNATION</TableCell>
                      <TableCell>QTE</TableCell>
                      <TableCell>PRIX UNITAIRE EUROS</TableCell>
                      <TableCell>MONTANT H.T EUROS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.invoiceLines.map((line, index) => (
                      <TableRow key={index}>
                        {line.historyAdvertisingLocation && (
                          <>
                            <TableCell>{line.product.name}</TableCell>
                            <TableCell>{formatDate(line.historyAdvertisingLocation.startDate)}</TableCell>
                            <TableCell>{formatDate(line.historyAdvertisingLocation.endDate)}</TableCell>
                          </>
                        )}
                        <TableCell>{line.description}</TableCell>
                        <TableCell>{line.quantity}</TableCell>
                        <TableCell>{formatNumber(line.price / 100)} €</TableCell>
                        <TableCell>{formatNumber((line.price / 100) * line.quantity)} €</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
              marginTop="20px"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                width="100%"
                textAlign={"right"}
                marginTop="20px"
              >
                {/* {Object.entries(taxRates).map(([rate, values]) => (
                  <Box key={rate}>
                    <Typography>
                      Taux de TVA {values.value}% :{" "}
                      {values.totalAmountTVA.toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </Typography>
                  </Box>
                ))}
                <Typography variant="p">
                  Montant total:{" "}
                  {totalAmount.toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </Typography> */}

                <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "80%" }}>Description</TableCell>
                        <TableCell>Montant</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(taxRates).map(([rate, values]) => (
                        <TableRow key={rate}>
                          <TableCell>Taux de TVA {values.value}%</TableCell>
                          <TableCell>{formatNumber(values.totalAmountTVA)} €</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight={700}>
                            Montant total
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={700}>
                            {formatNumber(totalAmount)} €
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            Accompte versé
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {formatNumber(downPayment)} €
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight={700}>
                            Montant restant à payer
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={700}>
                            {formatNumber(remainingAmount)} €
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {totalInstallments > 1 && (
                  <TableContainer component={Paper} sx={{ marginTop: "40px" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Paiement N°</TableCell>
                          <TableCell>Date d'échéance</TableCell>
                          <TableCell>Montant à payer</TableCell>
                          <TableCell>Montant Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData.payment.installmentSchedules.map(
                          (schedule, index) => {
                            const amountPerInstallment =
                              schedule.amountTtc / 100;
                            const totalPaid =
                              amountPerInstallment * (index + 1);

                            return (
                              <TableRow key={schedule.id}>
                                <TableCell>
                                  {schedule.installmentNumber}
                                </TableCell>
                                <TableCell>
                                  {new Date(
                                    schedule.dueDate
                                  ).toLocaleDateString("fr-FR")}
                                </TableCell>
                                <TableCell>
                                  {amountPerInstallment.toLocaleString(
                                    "fr-FR",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}{" "}
                                  €
                                </TableCell>
                                <TableCell>
                                  {totalPaid.toLocaleString("fr-FR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  €
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              left: "0",
              right: "0",
              position: "absolute",
              bottom: "0",
              textAlign: "center",
              width: "100%",
              fontWeight: "700",
              color: "#33b540",
            }}
          >
            Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de l8000E.
            RCS: 353 688 591 00067 Code APE: 7311Z
          </Box>
        </Box>
      )}
    </>
  );
}
