import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../theme";
import { CustomerModal } from "./customerModal";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { Table } from "../../components/table/Table";
import { toast, ToastContainer } from "react-toastify";
import Dashboard from "../dashboard/DashboardIndex.tsx";
import {
  HourglassBottom,
  ThumbDownOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const Customer = () => {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [customerIdToDelete, setCustomerIdToDelete] = useState(null);

  const handleOpen = () => setOpen(true);
  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const result = await store.customer.getCustomers();
      if (result.success) {
        const activeCustomers = result.data.filter(customer => !customer.deleted);
        setCustomersData(activeCustomers);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      console.log("Tentative de suppression du client avec l'ID :", customerIdToDelete);
      const result = await store.customer.deleteCustomer(customerIdToDelete);
      
      console.log("Résultat de la suppression :", result);
      
      if (result.success) {
        setCustomersData(customersData.filter(customer => customer.id !== customerIdToDelete));
        toast.success("Client supprimé avec succès !");
      } else {
        console.error("Erreur lors de la suppression du client :", result.error);
        toast.error("Erreur lors de la suppression du client");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du client :", error);
      toast.error("Erreur lors de la suppression du client");
    } finally {
      setConfirmDeleteOpen(false);
      setCustomerIdToDelete(null);
    }
  };

  const openConfirmDeleteModal = (id) => {
    setCustomerIdToDelete(id);
    setConfirmDeleteOpen(true);
  };

  useEffect(() => {
    fetchCustomers();
  }, [store.customer]);

  const columns = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center", flex: 1, cellClassName: "name-column--cell" },
    {
      field: "companyName",
      headerName: "NOM",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "contactFirstName",
      headerName: "PRÉNOM",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "officeNumber",
      headerName: "NUMÉRO",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "contactEmail",
      headerName: "MAIL",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "access",
      headerName: "RETARD PAIEMENT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            height="20px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="5px"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : colors.redAccent[700]
            }
          >
            {access === "Current" && <ThumbUpAltOutlined />}
            {access === "Pending" && <HourglassBottom />}
            {access === "Overdue" && <ThumbDownOutlined />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "EDITER",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { id } }) => (
        <strong>
          <Link to={`/customer/edit/${id}`}>
            <Button
              variant="contained"
              size="small"
              borderRadius="5px"
              style={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
              }}
            >
              ouvrir
            </Button>
          </Link>
        </strong>
      ),
      key: "edit",
    },
    {
      field: "delete",
      headerName: "SUPPRIMER LE CLIENT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { id } }) => (
        <Button color="error" variant="contained" onClick={() => openConfirmDeleteModal(id)}>
          <DeleteIcon />
        </Button>
      ),
    }
  ];

  return (
    <Box m="20px">
      <Dashboard
        title={"Clients"}
        subtitle={"Retrouvez toutes les données de vos clients"}
        element={
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            <AddIcon />
            Ajouter
          </Button>
        }
      />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : customersData.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <Typography variant="h6">Pas de clients trouvés</Typography>
        </Box>
      ) : (
        <Table Data={customersData} columns={columns} />
      )}
      <ToastContainer />
      <CustomerModal
        open={open}
        setOpen={setOpen}
        color={colors}
        fetchCustomers={fetchCustomers}
      />
      
      <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer ce client ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="secondary">
            Non
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Customer;
