import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import logo_cevep from "../../../img/logo/logo_cevep.webp";
import { TVA_RATES } from "../../../constants/TvaRate.ts";

export function GeneratePdf(invoiceDataObject) {
  const invoiceData = invoiceDataObject.invoiceData;

  const taxRates = {};
  const totalAmount = parseFloat(invoiceData.totalWithTax / 100);

  // Vérifier que les données de la facture sont disponibles et traiter les lignes
  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const tvaRate = TVA_RATES.find(rate => rate.id === line.taxRate)?.rate || 0;
      const amountHT = (line.price / 100) * line.quantity;
      const amountTVA = amountHT * (tvaRate / 100);

      if (!taxRates[tvaRate]) {
        taxRates[tvaRate] = {
          value: tvaRate,
          totalAmountTVA: 0,
        };
      }
      taxRates[tvaRate].totalAmountTVA += amountTVA;
    });
  }

  if (!invoiceData) {
    return null; // Renvoie null si les données de la facture ne sont pas disponibles
  }

  const downPayment = invoiceData.payment?.DepositAmount / 100 || 0;
  const remainingAmount = totalAmount - downPayment;
  const installmentSchedules = invoiceData.payment?.installmentSchedules || [];
  const totalInstallments = installmentSchedules.length;

  function formatNumber(value) {
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function formatDate(date) {
    return new Date(date).toLocaleDateString("fr-FR");
  }

  const styles = {
    page: {
      flexDirection: "column",
      padding: "12mm 15mm 18mm",
      fontSize: 10,
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: "#f0f0f0",
      padding: 3,
      minHeight: 25,
      alignItems: "center",
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
    },
    tableHeaderTotaux: {
      flexDirection: "row",
      backgroundColor: "#f0f0f0",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
      minHeight: 25,
      alignItems: "center",
    },
    tableCell: {
      borderRightWidth: 1,
      borderRightColor: "#ccc",
      padding: "3px 5px",
      height: "100%",
      justifyContent: "center",
    },
    tableCellContent: {
      flexShrink: 1,
      fontSize: 9,
    },
    tableCellHeader: {
      flexShrink: 1,
      fontSize: 9,
      fontWeight: "bold",
    },
    tableCellTotaux: {
      padding: "5px",
      borderRightWidth: 1,
      borderRightColor: "#ccc",
    },
    totals: {
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: 20,
    },
    paymentTable: {
      marginTop: 20,
      borderWidth: 1,
      borderColor: "#ccc",
      width: "100%",
    },
    paymentHeader: {
      flexDirection: "row",
      backgroundColor: "#f0f0f0",
      padding: 3,
      minHeight: 10,
      alignItems: "center",
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
    },
    paymentRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
      minHeight: 10,
      alignItems: "center",
    },
    paymentCell: {
      borderRightWidth: 1,
      borderRightColor: "#ccc",
      padding: "3px 5px",
      height: "100%",
      justifyContent: "center",
    },
    totalSection: {
      marginTop: 10,
      borderWidth: 1,
      borderColor: "#ccc",
      width: "100%",
    },
    footer: {
      position: "absolute",
      bottom: 20, // Positionnement à 20 unités du bas
      left: 50, // Alignement à gauche
      right: 0, // Alignement à droite pour occuper toute la largeur
      width: "100%", // S'assurer que le footer occupe 100% de la largeur
      textAlign: "center", // Centre le texte
      fontSize: 8, // Taille adaptée pour le footer
      color: "#333", // Couleur du texte
      borderTopWidth: 1, // Ligne de séparation au-dessus du footer (facultatif)
      borderColor: "#ccc", // Couleur de la bordure
      paddingTop: 5, // Ajout d'espace entre la bordure et le texte
      margin: 0, // Supprime toutes les marges implicites
      padding: 0, // Supprime tout padding implicite
    },
    footerText: {
      fontSize: 10,
      fontWeight: "bold",
      color: "#33b540", // Couleur du texte
      justifyContent: "center",
      alignItems: "center",
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ alignItems: "center" }}>
          <Image src={logo_cevep} style={{ width: 200 }} />
        </View>

        {/* En-tête de la facture */}
        <View style={{ marginTop: 50, flexDirection: "row" }}>
          <View style={{ width: "50%", alignItems: "center", fontWeight: "700" }}>
            <Text style={{ fontWeight: "bold" }}>16 F rue des Jardins</Text>
            <Text style={{ fontWeight: "bold" }}>95480 PIERRELAYE</Text>
            <Text style={{ fontWeight: "bold" }}>Tél. : 01.34.32.53.53</Text>
            <Text style={{ fontSize: 8, color: "#33b540", fontWeight: "bold" }}>
              E. Mail. : pubccvep@gmail.com
            </Text>
            <Text style={{ marginTop: 20, fontWeight: "bold", fontSize: 18 }}>
              Facture {invoiceData.number}
            </Text>
            <Text>{`Pierrelaye, le ${new Date(invoiceData.issuedAt).toLocaleDateString("fr-FR")}`}</Text>
          </View>

          <View style={{ width: "50%", alignItems: "center" }}>
            {invoiceData.customer.companyName && (
              <Text>Client: {invoiceData.customer.companyName}</Text>
            )}
            {invoiceData.customer.numberSiret && (
              <Text>N°SIRET: {invoiceData.customer.numberSiret}</Text>
            )}
            {invoiceData.customer.numberTva && (
              <Text>N°TVA: {invoiceData.customer.numberTva}</Text>
            )}
          </View>
        </View>

        {/* Tableau des lignes de facture */}
        <View
          style={{
            width: "100%",
            marginTop: 20,
            borderWidth: 1,
            borderColor: "#ccc",
          }}
        >
          <View style={styles.tableHeader}>
            {invoiceData.invoiceLines.some(line => line.historyAdvertisingLocation) ? (
              <>
                <View style={{ width: "15%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>PRODUIT</Text>
                </View>
                <View style={{ width: "15%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>DATE DE DÉBUT</Text>
                </View>
                <View style={{ width: "15%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>DATE DE FIN</Text>
                </View>
                <View style={{ width: "25%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>DESIGNATION</Text>
                </View>
                <View style={{ width: "10%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>QTE</Text>
                </View>
                <View style={{ width: "10%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>PRIX UNITAIRE</Text>
                </View>
                <View style={{ width: "10%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>MONTANT H.T</Text>
                </View>
              </>
            ) : (
              <>
                <View style={{ width: "40%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>DESIGNATION</Text>
                </View>
                <View style={{ width: "20%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>QTE</Text>
                </View>
                <View style={{ width: "20%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>PRIX UNITAIRE</Text>
                </View>
                <View style={{ width: "20%", ...styles.tableCell }}>
                  <Text style={styles.tableCellHeader}>MONTANT H.T</Text>
                </View>
              </>
            )}
          </View>

          {invoiceData.invoiceLines.map((line, index) => (
            <View key={index} style={styles.tableRow}>
              {line.historyAdvertisingLocation ? (
                <>
                  <View style={{ width: "15%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{line.product.name}</Text>
                  </View>
                  <View style={{ width: "15%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatDate(line.historyAdvertisingLocation.startDate)}</Text>
                  </View>
                  <View style={{ width: "15%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatDate(line.historyAdvertisingLocation.endDate)}</Text>
                  </View>
                  <View style={{ width: "25%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{line.description}</Text>
                  </View>
                  <View style={{ width: "10%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{line.quantity}</Text>
                  </View>
                  <View style={{ width: "10%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatNumber(line.price / 100)}€</Text>
                  </View>
                  <View style={{ width: "10%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatNumber((line.price / 100) * line.quantity)}€</Text>
                  </View>
                </>
              ) : (
                <>
                  <View style={{ width: "40%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{line.description}</Text>
                  </View>
                  <View style={{ width: "20%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{line.quantity}€</Text>
                  </View>
                  <View style={{ width: "20%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatNumber(line.price / 100)}€</Text>
                  </View>
                  <View style={{ width: "20%", ...styles.tableCell }}>
                    <Text style={styles.tableCellContent}>{formatNumber((line.price / 100) * line.quantity)}€</Text>
                  </View>
                </>
              )}
            </View>
          ))}
        </View>

        {/* Section Totaux */}
        <View style={styles.totalSection}>
          <View style={styles.tableHeaderTotaux}>
            <View style={{ width: "70%", ...styles.tableCellTotaux }}>
              <Text style={{ fontWeight: "bold" }}>Description</Text>
            </View>
            <View style={{ width: "30%", padding: "10px" }}>
              <Text style={{ fontWeight: "bold" }}>Montant</Text>
            </View>
          </View>

          {/* Lignes TVA */}
          {Object.entries(taxRates).map(([rate, values]) => (
            <View
              key={rate}
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                /* paddingVertical: 3, */
              }}
            >
              <View style={{ width: "70%", ...styles.tableCellTotaux }}>
                <Text>{`Montant de la TVA :  ${values.value}%`}</Text>
              </View>
              <View style={{ width: "30%", padding: "10px" }}>
                <Text>
                  {formatNumber(values.totalAmountTVA)} €
                </Text>
              </View>
            </View>
          ))}

          {/* Montant total */}
          <View
            style={{
              flexDirection: "row",
              borderTopWidth: 1,
              borderTopColor: "#ccc",
              /* paddingVertical: 1, */
              backgroundColor: "#f0f0f0",
            }}
          >
            <View style={{ width: "70%", ...styles.tableCellTotaux }}>
              <Text style={{ fontWeight: "bold" }}>Montant total</Text>
            </View>
            <View style={{ width: "30%", padding: "10px" }}>
              <Text style={{ fontWeight: "bold" }}>
                {formatNumber(totalAmount)} €
              </Text>
            </View>
          </View>

          {/* Acompte versé */}
          {downPayment > 0 && (
            <View
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                /* paddingVertical: 1, */
              }}
            >
              <View style={{ width: "70%", ...styles.tableCellTotaux }}>
                <Text>Acompte versé</Text>
              </View>
              <View style={{ width: "30%", padding: "10px" }}>
                <Text>
                  {formatNumber(downPayment)} €
                </Text>
              </View>
            </View>
          )}

          {/* Montant restant */}
          {downPayment > 0 && (
            <View
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                /* paddingVertical: 1, */
                backgroundColor: "#f0f0f0",
              }}
            >
              <View style={{ width: "70%", ...styles.tableCellTotaux }}>
                <Text style={{ fontWeight: "bold" }}>Montant restant à payer</Text>
              </View>
              <View style={{ width: "30%", padding: "10px" }}>
                <Text style={{ fontWeight: "bold" }}>
                  {formatNumber(remainingAmount)} €
                </Text>
              </View>
            </View>
          )}
        </View>

        {/* Tableau des paiements échelonnés (si plusieurs versements) */}
        {totalInstallments > 1 && (
          <View style={styles.paymentTable}>
            <View style={styles.paymentHeader}>
              <View style={{ width: "25%", ...styles.paymentCell }}>
                <Text style={styles.tableCellHeader}>Paiement N°</Text>
              </View>
              <View style={{ width: "25%", ...styles.paymentCell }}>
                <Text style={styles.tableCellHeader}>Date d'échéance</Text>
              </View>
              <View style={{ width: "25%", ...styles.paymentCell }}>
                <Text style={styles.tableCellHeader}>Montant à payer</Text>
              </View>
              <View style={{ width: "25%", ...styles.paymentCell }}>
                <Text style={styles.tableCellHeader}>Montant Total</Text>
              </View>
            </View>

            {installmentSchedules.map((schedule, index) => {
              const amountPerInstallment = schedule.amountTtc / 100;
              const totalPaid = amountPerInstallment * (index + 1);

              return (
                <View key={schedule.id} style={styles.paymentRow}>
                  <View style={{ width: "25%", ...styles.paymentCell }}>
                    <Text style={styles.tableCellContent}>
                      {schedule.installmentNumber}
                    </Text>
                  </View>
                  <View style={{ width: "25%", ...styles.paymentCell }}>
                    <Text style={styles.tableCellContent}>
                      {formatDate(schedule.dueDate)}
                    </Text>
                  </View>
                  <View style={{ width: "25%", ...styles.paymentCell }}>
                    <Text style={styles.tableCellContent}>
                      {formatNumber(amountPerInstallment)}€
                    </Text>
                  </View>
                  <View style={{ width: "25%", ...styles.paymentCell }}>
                    <Text style={styles.tableCellContent}>
                      {formatNumber(totalPaid)}€
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}

        {/* Section Pied de page */}
        <View>
            <Text>
              {" "}
              Conditions de réglement : A réception de la facture conditions
              d'escompte Intéréts de retard : 1,5 fois le taux d'intéret légal
              par mois Indemnité Forfaitaire de 40E. Pénalités applicable en cas
              de retard TVA N° FR51353688591 Acquittée sur les Encaissements
            </Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de 8000€. RCS: 353 688 591 00067 Code APE: 7311Z
          </Text>
        </View>
      </Page>
    </Document>
  );
}
