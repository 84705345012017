export interface TaxRate {
  id: string;
  rate: number;
}

export const TVA_RATES: TaxRate[] = [
  { id: "TVA_0", rate: 0.0 },
  { id: "TVA_55", rate: 5.5 },
  { id: "TVA_20", rate: 20.0 }
];

export const getTvaRateByValue = (value: number): string => {
  const found = TVA_RATES.find(rate => rate.rate === value);
  return found?.id ?? "TVA_20";
};

export const getDefaultTvaRate = (): TaxRate => {
  return {
    id: "TVA_20",
    rate: 20.0
  };
};
