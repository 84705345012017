import { Field, Formik } from "formik";
import * as Yup from "yup";
import { ThemeModal } from "../../components/modal/Modal";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { textFieldStyleModal } from "../../components/style/componentStyle";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useState } from "react";
import { toast } from "react-toastify";

const initialValues = {
  customerType: "professionnel",
  companyName: "",
  numberSiret: "",
  numberTva: "",
  contactTitle: "",
  contactLastName: "",
  contactFirstName: "",
  contactEmail: "",
  phone_number: "",
  officeNumber: "",
  contact_email: "",
  address: "",
  postalCode: "",
  city: "",
  country: "",
  paymentDaysCondition: 0,
};
/*
const initialValues = {
  customerType: "professionnel",
  companyName: "Entreprise Exemple",
  numberSiret: "12345678901234",
  numberTva: "FR123456789",
  contactTitle: "M.",
  contactLastName: "Dupont",
  contactFirstName: "Jean",
  contactEmail: "jean.dupont@example.com",
  phoneNumber: "0123456789",
  officeNumber: "9876543210",
  contactEmail: "contact@example.com",
  address: "123 Rue de l'Exemple",
  postalCode: "75001",
  city: "Paris",
  country: "France",
  paymentDaysCondition: 30,
};
*/
const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/; // Exemple de regex pour les numéros de téléphone
const postalCodeRegExp = /^\d{5}$/; // Exemple de regex pour les codes postaux (ajuster selon le pays)
const siretRegExp = /^\d{14}$/; // Exemple de regex pour les numéros SIRET français

const checkoutSchema = Yup.object({
  companyName: Yup.string().required("Nom de l'entreprise requis"),
  contactEmail: Yup.string().email("Email invalide"),
  contactFirstName: Yup.string(),
  contactLastName: Yup.string(),
  contactTitle: Yup.string(),
  country: Yup.string().required("Pays requis"),
  customerType: Yup.string().required("Type de client requis"),
  numberSiret: Yup.string().matches(siretRegExp, "Numéro SIRET invalide"),
  numberTva: Yup.string(),
  officeNumber: Yup.string().matches(phoneRegExp, "Numéro de bureau invalide"),
  paymentDaysCondition: Yup.string().required(
    "Conditions de paiement requises"
  ),
  postalCode: Yup.string().matches(postalCodeRegExp, "Code postal invalide"),
  city: Yup.string(),
  address: Yup.string(),
});

export function CustomerModal({ open, setOpen, fetchCustomers }) {
  
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Valeurs soumises :", values);
    try {
      const result = await store.customer.postCustomer(values);
      console.log("Résultat de la soumission :", result);
      if (result.success) {
        toast.success("Client ajouté avec succès !");
        fetchCustomers();
      } else {
        console.error("Erreur lors de la soumission :", result.error);
        toast.error("Erreur lors de l'ajout du client : " + result.error);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      toast.error("Erreur lors de l'envoi des données.");
    } finally {
      setIsLoading(false);
      setSubmitting(false);
      handleClose();
    }
  };

  const handleCheckErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      toast.error("Des erreurs sont présentes dans le formulaire.");
    }
  };

  const textFieldStyles = {
    '& .MuiInputBase-input': {
      color: 'black', 
    },
    '& .MuiInputLabel-root': {
      color: 'black', 
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black', 
    },
  };

  return (
    <ThemeModal open={open} onClose={handleClose}>
      <Box className="modal-content">
        <Box sx={{ background: colors.grey[400] }} className="modal-header">
          <Typography variant="h3" component="h2">
            Ajouter un client
          </Typography>
        </Box>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className="modal-scroll">
                <Typography id="modal-title">Ajouter un client</Typography>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                >
                  <Select
                    sx={textFieldStyleModal}
                    id="demo-simple-select"
                    value={values.customerType}
                    name="customerType"
                    onChange={handleChange}
                  >
                    <MenuItem value={"professionnel"}>professionnel</MenuItem>
                    <MenuItem value={"particulier"}>particulier</MenuItem>
                  </Select>

                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Nom de l'entreprise"
                    name="companyName"
                    id="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    error={touched.companyName && !!errors.companyName}
                    helpertext={touched.companyName && errors.companyName}
                  />

                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Email de contact"
                    name="contactEmail"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactEmail}
                    error={touched.contactEmail && !!errors.contactEmail}
                    helpertext={touched.contactEmail && errors.contactEmail}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Prénom du contact"
                    name="contactFirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactFirstName}
                    error={
                      touched.contactFirstName && !!errors.contactFirstName
                    }
                    helpertext={
                      touched.contactFirstName && errors.contactFirstName
                    }
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Nom de famille du contact"
                    name="contactLastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactLastName}
                    error={touched.contactLastName && !!errors.contactLastName}
                    helpertext={
                      touched.contactLastName && errors.contactLastName
                    }
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Titre du contact"
                    name="contactTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactTitle}
                    error={touched.contactTitle && !!errors.contactTitle}
                    helpertext={touched.contactTitle && errors.contactTitle}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Pays"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={touched.country && !!errors.country}
                    helpertext={touched.country && errors.country}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Type de client"
                    name="customerType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customerType}
                    error={touched.customerType && !!errors.customerType}
                    helpertext={touched.customerType && errors.customerType}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Numéro SIRET"
                    name="numberSiret"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberSiret}
                    error={touched.numberSiret && !!errors.numberSiret}
                    helpertext={touched.numberSiret && errors.numberSiret}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Numéro TVA"
                    name="numberTva"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberTva}
                    error={touched.numberTva && !!errors.numberTva}
                    helpertext={touched.numberTva && errors.numberTva}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Numéro de bureau"
                    name="officeNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.officeNumber}
                    error={touched.officeNumber && !!errors.officeNumber}
                    helpertext={touched.officeNumber && errors.officeNumber}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Conditions de paiement"
                    name="paymentDaysCondition"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentDaysCondition}
                    error={
                      touched.paymentDaysCondition &&
                      !!errors.paymentDaysCondition
                    }
                    helpertext={
                      touched.paymentDaysCondition &&
                      errors.paymentDaysCondition
                    }
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Code postal"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    error={touched.postalCode && !!errors.postalCode}
                    helpertext={touched.postalCode && errors.postalCode}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Ville"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && !!errors.city}
                    helpertext={touched.city && errors.city}
                  />
                  <TextField
                    sx={{ ...textFieldStyleModal, ...textFieldStyles }}
                    fullWidth
                    variant="filled"
                    label="Adresse"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && !!errors.address}
                    helpertext={touched.address && errors.address}
                  />
                </Box>
                <Typography id="modal-description">
                  Remplissez les détails du client
                </Typography>
              </Box>
              <Box
                sx={{ background: colors.grey[400] }}
                className="modal-footer"
              >
                <Stack spacing={2} direction="row">
                  <Button variant="contained" onClick={handleClose}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleCheckErrors(errors)}
                  >
                    Enregistrer
                  </Button>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ThemeModal>
  );
}
