import React from "react";
import { Grid, Paper, Typography, Box, TextField, Button } from "@mui/material";
import { InvoiceGenerationSchedule, InstallmentSchedule } from "./types";

interface PaymentDetailsProps {
  Values: {
    statut: string;
    installmentSchedule: InstallmentSchedule[];
    invoiceGenerationSchedule: InvoiceGenerationSchedule;
  };
  handleChange: (event: React.ChangeEvent<any>) => void;
  handleBlur: (event: React.FocusEvent<any>) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  Values,
  handleChange,
  handleBlur,
}) => {
  console.log("À l'intérieur du composant:", Values);

  return (
    <Grid item xs={6} sx={{ padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 4, minWidth: "400px" }}>
        <Typography variant="h5" gutterBottom>
          Détails des Paiements
        </Typography>
        <Box>
          {Values.invoiceGenerationSchedule ? (
            // Affichage pour les factures récurrentes
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Fréquence : {Values.invoiceGenerationSchedule.frequency}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Prochaine génération :{" "}
                  {new Date(
                    Values.invoiceGenerationSchedule.nextGenerationDate
                  ).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Durée de la récurrence :{" "}
                  {Values.invoiceGenerationSchedule.duration}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Nombre de récurrences restantes :{" "}
                  {Values.invoiceGenerationSchedule.remainingOccurrences}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Montant récurrent :{" "}
                  {(Values.invoiceGenerationSchedule.amountTtc / 100).toFixed(
                    2
                  )}{" "}
                  €
                </Typography>
                <Grid item xs={12}>
                  <Box sx={{ marginTop: 2, display: "flex", gap: 4 }}>
                    <Box sx={{ flexDirection: "column" }}>
                      <Typography variant="body2" color="textSecondary">
                        Date de l'échéance :{" "}
                        {Values.invoiceGenerationSchedule.nextGenerationDate
                          ? new Date(
                              Values.invoiceGenerationSchedule.nextGenerationDate
                            ).toLocaleDateString()
                          : "Non spécifiée"}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        Montant total :{" "}
                        {(
                          Values.invoiceGenerationSchedule.amountTtc / 100
                        ).toFixed(2)}{" "}
                        €
                      </Typography>
                    </Box>
                    <Grid item xs={6}>
                      {Values.invoiceGenerationSchedule.paidAt ? (
                        <Typography variant="body2" color="textSecondary">
                          Payé le :{" "}
                          {new Date(
                            Values.invoiceGenerationSchedule.paidAt
                          ).toLocaleDateString()}
                        </Typography>
                      ) : (
                        <TextField
                          type="date"
                          name="invoiceGenerationSchedule.paidAt"
                          placeholder="yyyy-mm-dd"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={Values.invoiceGenerationSchedule.paidAt || ""}
                        />
                      )}
                      {Values.invoiceGenerationSchedule.paidAt && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            const event = {
                              target: {
                                name: "invoiceGenerationSchedule.paidAt",
                                value: "",
                              },
                              persist: () => {},
                            } as React.ChangeEvent<any>;
                            handleChange(event);
                          }}
                          sx={{ mt: 1 }}
                        >
                          Remettre à zéro
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            // Affichage existant pour les factures simples
            Values.installmentSchedule.map((payment, index) => {
              const schedule = Values.payment?.installmentSchedules[index];
              return (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">
                      Date d'échéance :{" "}
                      {payment
                        ? new Date(payment.dueDate).toLocaleDateString() // Utilisation de schedule.dueDate
                        : "Non spécifiée"}
                    </Typography>

                    <Typography variant="subtitle1">
                      Montant: {(payment?.amount_ttc / 100).toFixed(2)} €{" "}
                      {/* Conversion en euros */}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {payment.paidDate ? (
                      <Typography variant="body2" color="textSecondary">
                        Payé le :{" "}
                        {new Date(payment.paidDate).toLocaleDateString()}
                      </Typography>
                    ) : (
                      <TextField
                        type="date"
                        name={`installmentSchedule[${index}].paidDate`} // Utilisation du `name` pour lier à Formik
                        placeholder="yyyy-mm-dd"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={payment.paidDate || ""}
                      />
                    )}
                    {/* Bouton pour remettre à zéro la date de paiement */}
                    {payment.paidDate && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          const event = {
                            target: {
                              name: `installmentSchedule[${index}].paidDate`,
                              value: "",
                            },
                            persist: () => {},
                          } as React.ChangeEvent<any>;
                          handleChange(event);
                        }} // Remettre à zéro
                        sx={{ mt: 1 }} // Marges pour espacer le bouton
                      >
                        Remettre à zéro
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default PaymentDetails;
