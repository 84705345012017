import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function BtnLocation() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <AddIcon />
        Ajouter
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component="a" href="/product/edit">
          Avec localisation
        </MenuItem>
        <MenuItem onClick={handleClose} component="a" href="/product/editnoloc">
          Sans localisation
        </MenuItem>
      </Menu>
    </>
  );
}
