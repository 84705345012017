import React from "react";
import {
  Box,
  Button,
  useTheme,
  CircularProgress
} from "@mui/material";
import { tokens } from "../../../../theme";
import CustomizedSteppers from "./StepperHeader.tsx";
import StepperComercial from "./StepperComercial.tsx";
import StepperEmplacement from "./StepperEmplacement.tsx";
import SteperDate from "./SteperDate.tsx";
import SteperRecapitulatif from "./SteperRecapitulatif.tsx";
import { useGlobalStore } from "../../../../store/globalStore.tsx";
import { toast } from "react-toastify";

interface User {
  "@id": string;
  "@type": string;
  id: number;
  email: string;
  roles: string[];
  firstName: string;
  lastName: string;
  phone: string;
  createdAt: string;
}

interface Product {
  "@id": string;
  "@type": string;
  id: number;
  ref: string;
  name: string;
  AdvertisingLocation: Array<{
    "@id": string;
    "@type": string;
    id: number;
    isTemporary?: boolean;
    historyAdvertisingLocations: Array<{
      "@id": string;
      "@type": string;
      customerId: {
        "@id": string;
        "@type": string;
        companyName: string;
      };
      AdvertisingLocation: string;
      startDate: string;
      endDate: string;
      currentlyActive: boolean;
      customerCompanyName: string;
    }>;
  }>;
  productLocalisation: {
    city: string;
  };
}

interface LocationCommercial {
  id: number;
  companyName: string;
  contactEmail: string;
  officeNumber: string;
  address: string;
}

interface LocationEmplacement {
  id: number;
  ref: string;
  name: string;
  advertisingLocationId?: number;
  city: string;
  isTemporary?: boolean;
  AdvertisingLocation?: Array<{
    id: number;
    isTemporary?: boolean;
    [key: string]: any;
  }>;
}

interface LocationDateRange {
  startDate: string;
  endDate: string;
}

interface LocationData {
  commercial?: LocationCommercial | null;
  emplacement?: LocationEmplacement | null;
  dateRange?: LocationDateRange;
}

const steps = [
  "Choisissez un commercial",
  "Choisir un emplacement",
  "Ajouter une location",
  "Récapitulatif",
];

// Mise à jour des props pour recevoir les données du parent
interface SteperLocationProps {
  users: User[];
  products: Product[];
  isLoading: boolean;
  selectedCommercial: string | null;
  selectedEmplacement: number | null;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  onCommercialSelect: (commercialId: string) => void;
  onEmplacementSelect: (emplacementId: number) => void;
  onDateChange: (startDate: string, endDate: string) => void;
  setDocumentType: (type: string) => void;
  onLocationDataUpdate: (data: Partial<LocationData>) => void;
  preselectedCommercial: string | null;
}

interface Commercial {
  id: number;
  companyName: string;
  contactEmail: string;
  officeNumber: string;
  address: string;
}

export default function SteperLocation({
  users,
  products,
  isLoading,
  selectedCommercial,
  selectedEmplacement,
  dateRange,
  onCommercialSelect,
  onEmplacementSelect,
  onDateChange,
  setDocumentType,
  onLocationDataUpdate,
  preselectedCommercial
}: SteperLocationProps) {
  const store = useGlobalStore();
  const [activeStep, setActiveStep] = React.useState(preselectedCommercial ? 1 : 0);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [commercials, setCommercials] = React.useState<Commercial[]>([]);
  const [isLoadingCommercials, setIsLoadingCommercials] = React.useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [temporaryLocations, setTemporaryLocations] = React.useState<Array<{
    ref: string;
    id: number;
    isTemporary: boolean;
  }>>([]);

  // Fonction pour récupérer les commerciaux
  const fetchCommercials = async () => {
    try {
      const result = await store.customer.getCustomers();
      if (result.success && result.data) {
        setCommercials(result.data.map(customer => ({
          id: customer.id,
          companyName: customer.companyName,
          contactEmail: customer.contactEmail,
          officeNumber: customer.officeNumber,
          address: customer.address
        })));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des commerciaux:", error);
    } finally {
      setIsLoadingCommercials(false);
    }
  };

  React.useEffect(() => {
    fetchCommercials();
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  const handleAddTemporaryLocation = React.useCallback((productRef: string) => {
    const tempId = -Math.floor(Math.random() * 1000);
    
    setTemporaryLocations(prev => {
      // Vérifier si un emplacement temporaire existe déjà pour ce produit
      const existingTempForProduct = prev.find(loc => loc.ref === productRef);
      if (existingTempForProduct) {
        return prev;
      }

      const newTemp = {
        ref: productRef,
        id: tempId,
        isTemporary: true
      };

      return [...prev, newTemp];
    });
  }, []);

  // Modifier les produits pour inclure les emplacements temporaires
  const allProducts = React.useMemo(() => {
    return products.map(product => {
      const tempLocsForProduct = temporaryLocations.filter(
        tempLoc => tempLoc.ref === product.ref
      );

      if (tempLocsForProduct.length === 0) {
        return product;
      }

      return {
        ...product,
        AdvertisingLocation: [
          ...product.AdvertisingLocation,
          ...tempLocsForProduct.map(tempLoc => ({
            "@id": `temp_${tempLoc.id}`,
            "@type": "AdvertisingLocation",
            id: tempLoc.id,
            HistoryAdvertisingLocations: [],
            isTemporary: true
          }))
        ]
      };
    });
  }, [products, temporaryLocations]);

  const getStepContent = (step: number) => {
    if (isCompleted) {
      return (
        <Box sx={{ 
          textAlign: 'center', 
          padding: '2rem',
          marginTop: '2rem'
        }}>
        </Box>
      );
    }

    switch (step) {
      case 0:
        return (
          <StepperComercial 
            onSelectCommercial={onCommercialSelect}
            selectedCommercial={selectedCommercial}
            commercials={commercials}
            isLoading={isLoadingCommercials}
          />
        );
      case 1:
        return (
          <StepperEmplacement 
            colors={colors}
            products={allProducts}
            onSelectEmplacement={onEmplacementSelect}
            selectedEmplacement={selectedEmplacement}
            isLoading={isLoading}
            onAddTemporaryLocation={handleAddTemporaryLocation}
          />
        );
      case 2:
        return (
          <SteperDate 
            onDateChange={onDateChange}
            dateRange={dateRange}
          />
        );
      case 3:
        const selectedCommercialData = commercials.find(c => c.id.toString() === selectedCommercial);
        
        // Modification de la recherche du produit sélectionné
        const selectedProduct = allProducts.find(p => 
          p.AdvertisingLocation.some(loc => loc.id === selectedEmplacement)
        );
        
        // Trouver l'emplacement spécifique sélectionné
        const selectedLocation = selectedProduct?.AdvertisingLocation.find(
          loc => loc.id === selectedEmplacement
        );
        
        // Créer un objet produit modifié pour le récapitulatif
        const productForRecap = selectedProduct ? {
          ...selectedProduct,
          AdvertisingLocation: selectedLocation ? [selectedLocation] : [],
          isTemporaryLocation: selectedLocation?.isTemporary || false
        } : undefined;
        
        return (
          <SteperRecapitulatif 
            colors={colors}
            commercial={selectedCommercialData}
            product={productForRecap}
            dateRange={dateRange}
          />
        );
      default:
        return ("Étape inconnue");
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      const selectedCommercialData = commercials.find(c => c.id.toString() === selectedCommercial);
      
      const selectedProduct = allProducts.find(p => 
        p.AdvertisingLocation.some(loc => loc.id === selectedEmplacement)
      );
      
      const selectedLocation = selectedProduct?.AdvertisingLocation.find(
        loc => loc.id === selectedEmplacement
      );

      // S'assurer que toutes les données nécessaires sont présentes
      if ( !selectedProduct || !selectedLocation || !dateRange.startDate || !dateRange.endDate) {
        toast.error("Veuillez remplir toutes les informations nécessaires");
        return;
      }

      const locationData: LocationData = {
        commercial: {
          id: selectedCommercialData.id,
          companyName: selectedCommercialData.companyName,
          contactEmail: selectedCommercialData.contactEmail,
          officeNumber: selectedCommercialData.officeNumber,
          address: selectedCommercialData.address
        },
        emplacement: {
          id: selectedProduct.id,
          ref: selectedProduct.ref,
          name: selectedProduct.name,
          city: selectedProduct.productLocalisation?.city || '',
          advertisingLocationId: selectedLocation.id,
          isTemporary: selectedLocation.isTemporary || false,
          AdvertisingLocation: [{
            id: selectedLocation.id,
            isTemporary: selectedLocation.isTemporary || false
          }]
        },
        dateRange: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        }
      };

      // Mettre à jour les données de location dans le composant parent
      onLocationDataUpdate(locationData);
      
      // Attendre que les données soient mises à jour avant de changer de vue
      setTimeout(() => {
        setIsCompleted(true);
        setDocumentType("facture");
      }, 100);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomizedSteppers steps={steps} activeStep={activeStep} />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        getStepContent(activeStep)
      )}
      {!isCompleted && (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={handleNext}
            sx={{
              backgroundColor: colors.greenAccent[800],
              color: "white",
              "&:hover": {
                backgroundColor: colors.greenAccent[600],
              },
            }}
          >
            {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
